import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Faq1() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section>
          <nav className='px-4 md:px-10 lg:px-32 py-7 bg-body'>
            <div className='container mx-auto px-4'>
              <div className='relative flex items-center justify-between'>
                <a className='inline-block' href='#'>
                  <img
                    className='h-10'
                    src='casper-assets/logos/casper-logo-white.svg'
                    alt=''
                  />
                </a>
                <div className='flex items-center justify-end'>
                  <div className='hidden lg:block mr-10'>
                    <a
                      className='inline-flex py-2 px-4 mr-4 items-center justify-center text-sm font-medium uppercase text-white hover:text-violet-500'
                      href='#'
                    >
                      SIGN IN
                    </a>
                    <a
                      className='inline-flex h-11 py-2 px-4 items-center justify-center text-sm font-medium uppercase text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full'
                      href='#'
                    >
                      SIGN UP
                    </a>
                  </div>
                  <button className='navbar-burger text-white hover:text-violet-500'>
                    <svg
                      width={32}
                      height={32}
                      viewBox='0 0 32 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M3 16H29'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M3 8H29'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M20 24L29 24'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </nav>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-violet-900 opacity-20' />
            <nav className='relative flex flex-col py-7 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='casper-assets/logos/casper-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      FEATURED
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      SOLUTIONS
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      PRODUCTS
                    </a>
                  </li>
                  <li>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      ARTICLES
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex py-2 px-4 mb-4 items-center justify-center text-sm font-medium uppercase text-violet-900 hover:text-violet-500'
                  href='#'
                >
                  SIGN IN
                </a>
                <a
                  className='flex h-11 py-2 px-4 items-center justify-center text-sm font-medium uppercase text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full'
                  href='#'
                >
                  SIGN UP
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-body overflow-hidden'>
          <div className='relative container mx-auto px-4'>
            <div className='relative flex flex-wrap -mx-4'>
              <div className='w-full lg:w-1/2 xl:w-2/5 px-4 pb-16 lg:pb-32'>
                <div className='relative max-w-md mx-auto lg:mx-0'>
                  <div className='absolute top-0 left-0 -mt-80 -ml-40 w-170 h-170 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
                  <div className='relative'>
                    <h2 className='font-heading text-4xl xs:text-5xl sm:text-6xl font-medium text-white tracking-tight mb-6'>
                      Getting Started
                    </h2>
                    <div className='pl-8 py-3 border-l-2 border-white border-opacity-10'>
                      <ul>
                        <li className='mb-5'>
                          <a
                            className='flex items-center text-white hover:text-violet-600'
                            href='#'
                          >
                            <span>
                              <svg
                                width={12}
                                height={12}
                                viewBox='0 0 12 12'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle
                                  cx={6}
                                  cy={6}
                                  r={5}
                                  stroke='#C3C6CE'
                                  strokeWidth={2}
                                />
                              </svg>
                            </span>
                            <span className='ml-3 text-xl tracking-tight'>
                              Getting Started
                            </span>
                          </a>
                        </li>
                        <li className='mb-5'>
                          <a
                            className='flex items-center text-white hover:text-violet-600'
                            href='#'
                          >
                            <span>
                              <svg
                                width={12}
                                height={12}
                                viewBox='0 0 12 12'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle
                                  cx={6}
                                  cy={6}
                                  r={5}
                                  stroke='#C3C6CE'
                                  strokeWidth={2}
                                />
                              </svg>
                            </span>
                            <span className='ml-3 text-xl tracking-tight'>
                              How we can collaborate
                            </span>
                          </a>
                        </li>
                        <li className='mb-5'>
                          <a
                            className='flex items-center text-white hover:text-violet-600'
                            href='#'
                          >
                            <span>
                              <svg
                                width={12}
                                height={12}
                                viewBox='0 0 12 12'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle
                                  cx={6}
                                  cy={6}
                                  r={5}
                                  stroke='#C3C6CE'
                                  strokeWidth={2}
                                />
                              </svg>
                            </span>
                            <span className='ml-3 text-xl tracking-tight'>
                              How to use Coca
                            </span>
                          </a>
                        </li>
                        <li>
                          <a
                            className='flex items-center text-white hover:text-violet-600'
                            href='#'
                          >
                            <span>
                              <svg
                                width={12}
                                height={12}
                                viewBox='0 0 12 12'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <circle
                                  cx={6}
                                  cy={6}
                                  r={5}
                                  stroke='#C3C6CE'
                                  strokeWidth={2}
                                />
                              </svg>
                            </span>
                            <span className='ml-3 text-xl tracking-tight'>
                              How to subscription
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <img
                      className='block mt-14'
                      src='casper-assets/services/robot-left-side.png'
                      alt=''
                    />
                  </div>
                </div>
              </div>
              <div className='relative w-full lg:w-1/2 xl:w-3/5 px-4'>
                <div className='absolute bottom-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
                <div className='relative max-w-lg lg:max-w-none mx-auto'>
                  <div className='flex mb-12 px-8 h-18 items-center bg-white bg-opacity-10 rounded-full'>
                    <button className='inline-block text-white hover:text-violet-600'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M21.7099 20.29L17.9999 16.61C19.44 14.8144 20.1374 12.5353 19.9487 10.2413C19.76 7.94733 18.6996 5.81281 16.9854 4.27667C15.2713 2.74053 13.0337 1.91954 10.7328 1.9825C8.43194 2.04546 6.24263 2.98759 4.61505 4.61517C2.98747 6.24275 2.04534 8.43207 1.98237 10.7329C1.91941 13.0338 2.74041 15.2714 4.27655 16.9855C5.81269 18.6997 7.94721 19.7601 10.2412 19.9488C12.5352 20.1375 14.8143 19.4401 16.6099 18L20.2899 21.68C20.3829 21.7738 20.4935 21.8482 20.6153 21.8989C20.7372 21.9497 20.8679 21.9758 20.9999 21.9758C21.1319 21.9758 21.2626 21.9497 21.3845 21.8989C21.5063 21.8482 21.6169 21.7738 21.7099 21.68C21.8901 21.4936 21.9909 21.2444 21.9909 20.985C21.9909 20.7257 21.8901 20.4765 21.7099 20.29ZM10.9999 18C9.61544 18 8.26206 17.5895 7.11091 16.8203C5.95977 16.0511 5.06256 14.9579 4.53275 13.6788C4.00293 12.3997 3.86431 10.9923 4.13441 9.63439C4.4045 8.27653 5.07119 7.02925 6.05016 6.05028C7.02912 5.07131 8.27641 4.40463 9.63427 4.13453C10.9921 3.86443 12.3996 4.00306 13.6787 4.53287C14.9578 5.06268 16.051 5.95989 16.8202 7.11103C17.5894 8.26218 17.9999 9.61556 17.9999 11C17.9999 12.8565 17.2624 14.637 15.9497 15.9498C14.6369 17.2625 12.8564 18 10.9999 18Z'
                          fill='currentColor'
                        />
                      </svg>
                    </button>
                    <input
                      className='h-full w-full pl-6 py-6 bg-transparent text-base tracking-tight text-gray-400 placeholder-gray-500 outline-none'
                      type='search'
                      placeholder='Search question, keywords, articles'
                    />
                  </div>
                  <div>
                    <h5 className='text-xl text-white font-medium tracking-tight mb-8'>
                      GETTING STARTED
                    </h5>
                    <button className='group block w-full pb-6 mb-6 text-left border-b-2 border-white border-opacity-10'>
                      <div className='flex items-start justify-between'>
                        <div className='pr-4'>
                          <h4 className='text-xl text-white group-hover:text-violet-500 tracking-tight font-medium'>
                            Do you provide a complete design style?
                          </h4>
                          <p className='hidden group-hover:block max-w-xl mt-2 text-lg tracking-tight text-gray-400'>
                            Pretium ac auctor quis urna orci feugiat. Vulputate
                            tellus velit tellus orci auctor vel nulla facilisi
                            ut. Dummy conten amazing Ante nunc risus viverra
                            vivamus.
                          </p>
                        </div>
                        <span className='inline-block transform group-hover:rotate-180 text-white group-hover:text-violet-500'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.71 15.54L18.36 9.87998C18.4537 9.78702 18.5281 9.67642 18.5789 9.55456C18.6296 9.4327 18.6558 9.30199 18.6558 9.16998C18.6558 9.03797 18.6296 8.90726 18.5789 8.78541C18.5281 8.66355 18.4537 8.55294 18.36 8.45998C18.1726 8.27373 17.9191 8.16919 17.655 8.16919C17.3908 8.16919 17.1373 8.27373 16.95 8.45998L11.95 13.41L6.99996 8.45998C6.8126 8.27373 6.55915 8.16919 6.29496 8.16919C6.03078 8.16919 5.77733 8.27373 5.58996 8.45998C5.49548 8.5526 5.42031 8.66304 5.36881 8.78492C5.31731 8.90679 5.29051 9.03767 5.28996 9.16998C5.29051 9.30229 5.31731 9.43317 5.36881 9.55505C5.42031 9.67692 5.49548 9.78737 5.58996 9.87998L11.24 15.54C11.3336 15.6415 11.4473 15.7225 11.5738 15.7779C11.7003 15.8333 11.8369 15.8619 11.975 15.8619C12.1131 15.8619 12.2497 15.8333 12.3762 15.7779C12.5027 15.7225 12.6163 15.6415 12.71 15.54Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                      </div>
                    </button>
                    <button className='group block w-full pb-6 mb-6 text-left border-b-2 border-white border-opacity-10'>
                      <div className='flex items-start justify-between'>
                        <div className='pr-4'>
                          <h4 className='text-xl text-white group-hover:text-violet-500 tracking-tight font-medium'>
                            Do you have any terms &amp; conditions?
                          </h4>
                          <p className='hidden group-hover:block max-w-xl mt-2 text-lg tracking-tight text-gray-400'>
                            Pretium ac auctor quis urna orci feugiat. Vulputate
                            tellus velit tellus orci auctor vel nulla facilisi
                            ut. Dummy conten amazing Ante nunc risus viverra
                            vivamus.
                          </p>
                        </div>
                        <span className='inline-block transform group-hover:rotate-180 text-white group-hover:text-violet-500'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.71 15.54L18.36 9.87998C18.4537 9.78702 18.5281 9.67642 18.5789 9.55456C18.6296 9.4327 18.6558 9.30199 18.6558 9.16998C18.6558 9.03797 18.6296 8.90726 18.5789 8.78541C18.5281 8.66355 18.4537 8.55294 18.36 8.45998C18.1726 8.27373 17.9191 8.16919 17.655 8.16919C17.3908 8.16919 17.1373 8.27373 16.95 8.45998L11.95 13.41L6.99996 8.45998C6.8126 8.27373 6.55915 8.16919 6.29496 8.16919C6.03078 8.16919 5.77733 8.27373 5.58996 8.45998C5.49548 8.5526 5.42031 8.66304 5.36881 8.78492C5.31731 8.90679 5.29051 9.03767 5.28996 9.16998C5.29051 9.30229 5.31731 9.43317 5.36881 9.55505C5.42031 9.67692 5.49548 9.78737 5.58996 9.87998L11.24 15.54C11.3336 15.6415 11.4473 15.7225 11.5738 15.7779C11.7003 15.8333 11.8369 15.8619 11.975 15.8619C12.1131 15.8619 12.2497 15.8333 12.3762 15.7779C12.5027 15.7225 12.6163 15.6415 12.71 15.54Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                      </div>
                    </button>
                    <button className='group block w-full pb-6 mb-6 text-left border-b-2 border-white border-opacity-10'>
                      <div className='flex items-start justify-between'>
                        <div className='pr-4'>
                          <h4 className='text-xl text-white group-hover:text-violet-500 tracking-tight font-medium'>
                            How to apply Casper to our project?
                          </h4>
                          <p className='hidden group-hover:block max-w-xl mt-2 text-lg tracking-tight text-gray-400'>
                            Pretium ac auctor quis urna orci feugiat. Vulputate
                            tellus velit tellus orci auctor vel nulla facilisi
                            ut. Dummy conten amazing Ante nunc risus viverra
                            vivamus.
                          </p>
                        </div>
                        <span className='inline-block transform group-hover:rotate-180 text-white group-hover:text-violet-500'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.71 15.54L18.36 9.87998C18.4537 9.78702 18.5281 9.67642 18.5789 9.55456C18.6296 9.4327 18.6558 9.30199 18.6558 9.16998C18.6558 9.03797 18.6296 8.90726 18.5789 8.78541C18.5281 8.66355 18.4537 8.55294 18.36 8.45998C18.1726 8.27373 17.9191 8.16919 17.655 8.16919C17.3908 8.16919 17.1373 8.27373 16.95 8.45998L11.95 13.41L6.99996 8.45998C6.8126 8.27373 6.55915 8.16919 6.29496 8.16919C6.03078 8.16919 5.77733 8.27373 5.58996 8.45998C5.49548 8.5526 5.42031 8.66304 5.36881 8.78492C5.31731 8.90679 5.29051 9.03767 5.28996 9.16998C5.29051 9.30229 5.31731 9.43317 5.36881 9.55505C5.42031 9.67692 5.49548 9.78737 5.58996 9.87998L11.24 15.54C11.3336 15.6415 11.4473 15.7225 11.5738 15.7779C11.7003 15.8333 11.8369 15.8619 11.975 15.8619C12.1131 15.8619 12.2497 15.8333 12.3762 15.7779C12.5027 15.7225 12.6163 15.6415 12.71 15.54Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                      </div>
                    </button>
                    <button className='group block w-full pb-6 mb-6 text-left border-b-2 border-white border-opacity-10'>
                      <div className='flex items-start justify-between'>
                        <div className='pr-4'>
                          <h4 className='text-xl text-white group-hover:text-violet-500 tracking-tight font-medium'>
                            How was the Terms &amp; Condition?
                          </h4>
                          <p className='hidden group-hover:block max-w-xl mt-2 text-lg tracking-tight text-gray-400'>
                            Pretium ac auctor quis urna orci feugiat. Vulputate
                            tellus velit tellus orci auctor vel nulla facilisi
                            ut. Dummy conten amazing Ante nunc risus viverra
                            vivamus.
                          </p>
                        </div>
                        <span className='inline-block transform group-hover:rotate-180 text-white group-hover:text-violet-500'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.71 15.54L18.36 9.87998C18.4537 9.78702 18.5281 9.67642 18.5789 9.55456C18.6296 9.4327 18.6558 9.30199 18.6558 9.16998C18.6558 9.03797 18.6296 8.90726 18.5789 8.78541C18.5281 8.66355 18.4537 8.55294 18.36 8.45998C18.1726 8.27373 17.9191 8.16919 17.655 8.16919C17.3908 8.16919 17.1373 8.27373 16.95 8.45998L11.95 13.41L6.99996 8.45998C6.8126 8.27373 6.55915 8.16919 6.29496 8.16919C6.03078 8.16919 5.77733 8.27373 5.58996 8.45998C5.49548 8.5526 5.42031 8.66304 5.36881 8.78492C5.31731 8.90679 5.29051 9.03767 5.28996 9.16998C5.29051 9.30229 5.31731 9.43317 5.36881 9.55505C5.42031 9.67692 5.49548 9.78737 5.58996 9.87998L11.24 15.54C11.3336 15.6415 11.4473 15.7225 11.5738 15.7779C11.7003 15.8333 11.8369 15.8619 11.975 15.8619C12.1131 15.8619 12.2497 15.8333 12.3762 15.7779C12.5027 15.7225 12.6163 15.6415 12.71 15.54Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                      </div>
                    </button>
                    <button className='group block w-full pb-6 text-left border-b-2 border-white border-opacity-10'>
                      <div className='flex items-start justify-between'>
                        <div className='pr-4'>
                          <h4 className='text-xl text-white group-hover:text-violet-500 tracking-tight font-medium'>
                            How much we can buy this product?
                          </h4>
                          <p className='hidden group-hover:block max-w-xl mt-2 text-lg tracking-tight text-gray-400'>
                            Pretium ac auctor quis urna orci feugiat. Vulputate
                            tellus velit tellus orci auctor vel nulla facilisi
                            ut. Dummy conten amazing Ante nunc risus viverra
                            vivamus.
                          </p>
                        </div>
                        <span className='inline-block transform group-hover:rotate-180 text-white group-hover:text-violet-500'>
                          <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.71 15.54L18.36 9.87998C18.4537 9.78702 18.5281 9.67642 18.5789 9.55456C18.6296 9.4327 18.6558 9.30199 18.6558 9.16998C18.6558 9.03797 18.6296 8.90726 18.5789 8.78541C18.5281 8.66355 18.4537 8.55294 18.36 8.45998C18.1726 8.27373 17.9191 8.16919 17.655 8.16919C17.3908 8.16919 17.1373 8.27373 16.95 8.45998L11.95 13.41L6.99996 8.45998C6.8126 8.27373 6.55915 8.16919 6.29496 8.16919C6.03078 8.16919 5.77733 8.27373 5.58996 8.45998C5.49548 8.5526 5.42031 8.66304 5.36881 8.78492C5.31731 8.90679 5.29051 9.03767 5.28996 9.16998C5.29051 9.30229 5.31731 9.43317 5.36881 9.55505C5.42031 9.67692 5.49548 9.78737 5.58996 9.87998L11.24 15.54C11.3336 15.6415 11.4473 15.7225 11.5738 15.7779C11.7003 15.8333 11.8369 15.8619 11.975 15.8619C12.1131 15.8619 12.2497 15.8333 12.3762 15.7779C12.5027 15.7225 12.6163 15.6415 12.71 15.54Z'
                              fill='currentColor'
                            />
                          </svg>
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-body overflow-hidden'>
          <div className='relative container mx-auto px-4'>
            <div className='relative max-w-sm md:max-w-8xl mx-auto'>
              <div className='absolute top-0 left-0 -mt-96 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
              <div className='absolute bottom-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
              <div className='relative flex flex-wrap items-end -mx-4 mb-12'>
                <div className='w-full lg:w-3/5 xl:w-auto px-4 mb-8 lg:mb-0'>
                  <div>
                    <span className='block text-lg text-gray-500 tracking-tight mb-6'>
                      Type Caption Here
                    </span>
                    <h2 className='font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight'>
                      Recent Blog &amp; Articles
                    </h2>
                  </div>
                </div>
                <div className='w-full lg:w-2/5 xl:w-auto ml-auto px-4 lg:text-right'>
                  <a
                    className='group inline-flex w-full md:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-violet-800 bg-violet-500 hover:bg-white transition duration-200 rounded-full'
                    href='#'
                  >
                    <span className='mr-2'>SEE OUR PLANS</span>
                    <svg
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z'
                        fill='currentColor'
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className='relative flex flex-wrap -mx-4'>
                <div className='w-full md:w-1/3 px-4 mb-12 lg:mb-0'>
                  <div>
                    <img
                      className='block w-full mb-6'
                      src='casper-assets/blog/post-image1.png'
                      alt=''
                    />
                    <h3 className='text-2xl text-white font-medium mb-4'>
                      Type you blog title here
                    </h3>
                    <p className='text-sm text-gray-300 leading-snug mb-4'>
                      Gain valuable insights into your users' preferences,
                      behavior, and frequently asked questions. Simply dummy
                      text of the printing and typesetting industry.
                    </p>
                    <a className='group flex items-center' href='#'>
                      <div className='flex items-center justify-center w-14 h-14 rounded-full border border-gray-700 group-hover:bg-violet-600 group-hover:border-violet-600 transition duration-200'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M17.92 11.62C17.8724 11.4973 17.801 11.3851 17.71 11.29L12.71 6.29C12.6168 6.19676 12.5061 6.1228 12.3842 6.07234C12.2624 6.02188 12.1319 5.99591 12 5.99591C11.7337 5.99591 11.4783 6.1017 11.29 6.29C11.1968 6.38324 11.1228 6.49393 11.0723 6.61575C11.0219 6.73758 10.9959 6.86814 10.9959 7C10.9959 7.2663 11.1017 7.5217 11.29 7.71L14.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H14.59L11.29 16.29C11.1963 16.383 11.1219 16.4936 11.0711 16.6154C11.0203 16.7373 10.9942 16.868 10.9942 17C10.9942 17.132 11.0203 17.2627 11.0711 17.3846C11.1219 17.5064 11.1963 17.617 11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L17.71 12.71C17.801 12.6149 17.8724 12.5028 17.92 12.38C18.02 12.1365 18.02 11.8635 17.92 11.62Z'
                            fill='white'
                          />
                        </svg>
                      </div>
                      <span className='ml-5 text-sm font-medium text-white'>
                        Read more
                      </span>
                    </a>
                  </div>
                </div>
                <div className='w-full md:w-1/3 px-4 mb-12 lg:mb-0'>
                  <div>
                    <img
                      className='block w-full mb-6'
                      src='casper-assets/blog/post-image2.png'
                      alt=''
                    />
                    <h3 className='text-2xl text-white font-medium mb-4'>
                      Type you blog title here
                    </h3>
                    <p className='text-sm text-gray-300 leading-snug mb-4'>
                      Gain valuable insights into your users' preferences,
                      behavior, and frequently asked questions. Simply dummy
                      text of the printing and typesetting industry.
                    </p>
                    <a className='group flex items-center' href='#'>
                      <div className='flex items-center justify-center w-14 h-14 rounded-full border border-gray-700 group-hover:bg-violet-600 group-hover:border-violet-600 transition duration-200'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M17.92 11.62C17.8724 11.4973 17.801 11.3851 17.71 11.29L12.71 6.29C12.6168 6.19676 12.5061 6.1228 12.3842 6.07234C12.2624 6.02188 12.1319 5.99591 12 5.99591C11.7337 5.99591 11.4783 6.1017 11.29 6.29C11.1968 6.38324 11.1228 6.49393 11.0723 6.61575C11.0219 6.73758 10.9959 6.86814 10.9959 7C10.9959 7.2663 11.1017 7.5217 11.29 7.71L14.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H14.59L11.29 16.29C11.1963 16.383 11.1219 16.4936 11.0711 16.6154C11.0203 16.7373 10.9942 16.868 10.9942 17C10.9942 17.132 11.0203 17.2627 11.0711 17.3846C11.1219 17.5064 11.1963 17.617 11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L17.71 12.71C17.801 12.6149 17.8724 12.5028 17.92 12.38C18.02 12.1365 18.02 11.8635 17.92 11.62Z'
                            fill='white'
                          />
                        </svg>
                      </div>
                      <span className='ml-5 text-sm font-medium text-white'>
                        Read more
                      </span>
                    </a>
                  </div>
                </div>
                <div className='w-full md:w-1/3 px-4'>
                  <div>
                    <img
                      className='block w-full mb-6'
                      src='casper-assets/blog/post-image3.png'
                      alt=''
                    />
                    <h3 className='text-2xl text-white font-medium mb-4'>
                      Type you blog title here
                    </h3>
                    <p className='text-sm text-gray-300 leading-snug mb-4'>
                      Gain valuable insights into your users' preferences,
                      behavior, and frequently asked questions. Simply dummy
                      text of the printing and typesetting industry.
                    </p>
                    <a className='group flex items-center' href='#'>
                      <div className='flex items-center justify-center w-14 h-14 rounded-full border border-gray-700 group-hover:bg-violet-600 group-hover:border-violet-600 transition duration-200'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M17.92 11.62C17.8724 11.4973 17.801 11.3851 17.71 11.29L12.71 6.29C12.6168 6.19676 12.5061 6.1228 12.3842 6.07234C12.2624 6.02188 12.1319 5.99591 12 5.99591C11.7337 5.99591 11.4783 6.1017 11.29 6.29C11.1968 6.38324 11.1228 6.49393 11.0723 6.61575C11.0219 6.73758 10.9959 6.86814 10.9959 7C10.9959 7.2663 11.1017 7.5217 11.29 7.71L14.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H14.59L11.29 16.29C11.1963 16.383 11.1219 16.4936 11.0711 16.6154C11.0203 16.7373 10.9942 16.868 10.9942 17C10.9942 17.132 11.0203 17.2627 11.0711 17.3846C11.1219 17.5064 11.1963 17.617 11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L17.71 12.71C17.801 12.6149 17.8724 12.5028 17.92 12.38C18.02 12.1365 18.02 11.8635 17.92 11.62Z'
                            fill='white'
                          />
                        </svg>
                      </div>
                      <span className='ml-5 text-sm font-medium text-white'>
                        Read more
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 sm:pt-24 bg-body overflow-hidden'>
          <img
            className='hidden sm:block absolute top-0 left-0 w-32 lg:w-auto mt-24 lg:mt-16 xl:mt-10 ml-12'
            src='casper-assets/footer/gun-bubble.png'
            alt=''
          />
          <div className='relative container mx-auto px-4'>
            <div className='absolute top-0 right-0 -mt-96 -mr-52 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
            <div className='relative'>
              <div className='text-center mb-20'>
                <a className='inline-block mb-20' href='#'>
                  <img
                    className='block'
                    src='casper-assets/footer/logo-c-only.svg'
                    alt=''
                  />
                </a>
                <ul className='md:flex justify-center -mx-4 xl:-mx-8 -mb-4'>
                  <li className='px-4 xl:px-8 mb-4'>
                    <a
                      className='inline-block text-lg tracking-tight text-white hover:text-violet-600'
                      href='#'
                    >
                      Product
                    </a>
                  </li>
                  <li className='px-4 xl:px-8 mb-4'>
                    <a
                      className='inline-block text-lg tracking-tight text-white hover:text-violet-600'
                      href='#'
                    >
                      Features
                    </a>
                  </li>
                  <li className='px-4 xl:px-8 mb-4'>
                    <a
                      className='inline-block text-lg tracking-tight text-white hover:text-violet-600'
                      href='#'
                    >
                      Pricing
                    </a>
                  </li>
                  <li className='px-4 xl:px-8 mb-4'>
                    <a
                      className='inline-block text-lg tracking-tight text-white hover:text-violet-600'
                      href='#'
                    >
                      Resources
                    </a>
                  </li>
                  <li className='px-4 xl:px-8 mb-4'>
                    <a
                      className='inline-block text-lg tracking-tight text-white hover:text-violet-600'
                      href='#'
                    >
                      Careers
                    </a>
                  </li>
                  <li className='px-4 xl:px-8 mb-4'>
                    <a
                      className='inline-block text-lg tracking-tight text-white hover:text-violet-600'
                      href='#'
                    >
                      Help
                    </a>
                  </li>
                  <li className='px-4 xl:px-8 mb-4'>
                    <a
                      className='inline-block text-lg tracking-tight text-white hover:text-violet-600'
                      href='#'
                    >
                      Privacy
                    </a>
                  </li>
                </ul>
              </div>
              <div className='pt-10 border-t border-gray-800'>
                <div className='md:flex items-center justify-between'>
                  <div className='mb-6 md:mb-0'>
                    <span className='text-gray-600 tracking-tight'>
                      © Casper. All rights reserved
                    </span>
                  </div>
                  <div>
                    <a
                      className='inline-block mr-10 text-white hover:underline tracking-tight'
                      href='#'
                    >
                      Terms &amp; Conditions
                    </a>
                    <a
                      className='inline-block text-white hover:underline tracking-tight'
                      href='#'
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

