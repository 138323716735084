import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index2() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='relative bg-body overflow-hidden'>
          <nav className='relative z-10 py-7'>
            <div className='container mx-auto px-4'>
              <div className='relative flex items-center justify-between'>
                <a className='inline-block' href='#'>
                  <img
                    className='h-10'
                    src='casper-assets/logos/casper-logo-white.svg'
                    alt=''
                  />
                </a>
                <div className='flex items-center justify-end'>
                  <div className='hidden lg:block mr-10'>
                    <a
                      className='inline-flex py-2 px-4 mr-4 items-center justify-center text-sm font-medium uppercase text-white hover:text-violet-500'
                      href='#'
                    >
                      SIGN IN
                    </a>
                    <a
                      className='inline-flex h-11 py-2 px-4 items-center justify-center text-sm font-medium uppercase text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full'
                      href='#'
                    >
                      SIGN UP
                    </a>
                  </div>
                  <button className='navbar-burger text-white hover:text-violet-500'>
                    <svg
                      width={32}
                      height={32}
                      viewBox='0 0 32 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M3 16H29'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M3 8H29'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M20 24L29 24'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </nav>
          <div className='relative pt-24 lg:pt-44 pb-40 lg:pb-72'>
            <div className='relative z-10 container mx-auto px-4'>
              <div className='flex flex-wrap -mx-4 items-center'>
                <div className='w-full lg:w-1/2 xl:w-3/5 px-4 mb-32 lg:mb-0'>
                  <div className='max-w-md mx-auto lg:max-w-none'>
                    <h1 className='font-heading text-4xl sm:text-6xl md:text-7xl xl:text-8xl text-white font-semibold leading-none mb-8'>
                      INTRODUCING CASPER AI
                    </h1>
                    <p className='text-2xl text-gray-400 mb-8'>
                      <span className='block'>Welcome to ChatBotX -</span>
                      <span className='block'>
                        Your Intelligent AI Chat Assistant!
                      </span>
                    </p>
                    <a
                      className='group inline-flex h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full'
                      href='#'
                    >
                      <span className='mr-2'>TRY IT FOR FREE</span>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 xl:w-2/5 px-4'>
                  <div className='xl:inline-block relative'>
                    <img
                      className='absolute top-0 right-0 -mt-8 mr-12 animate-spinStar'
                      src='casper-assets/headers/blink-sm.png'
                      alt=''
                    />
                    <img
                      className='absolute bottom-0 left-0 lg:-ml-24 xl:-ml-40 animate-spinStar'
                      src='casper-assets/headers/blink-md.png'
                      alt=''
                    />
                    <img
                      className='block px-10 md:px-0 mx-auto lg:mr-0'
                      src='casper-assets/headers/oval-robot-photo.png'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
            <img
              className='absolute bottom-0 left-0 w-full'
              src='casper-assets/headers/bg-bottom-lines.png'
              alt=''
            />
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-violet-900 opacity-20' />
            <nav className='relative flex flex-col py-7 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='casper-assets/logos/casper-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      FEATURED
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      SOLUTIONS
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      PRODUCTS
                    </a>
                  </li>
                  <li>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      ARTICLES
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex py-2 px-4 mb-4 items-center justify-center text-sm font-medium uppercase text-violet-900 hover:text-violet-500'
                  href='#'
                >
                  SIGN IN
                </a>
                <a
                  className='flex h-11 py-2 px-4 items-center justify-center text-sm font-medium uppercase text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full'
                  href='#'
                >
                  SIGN UP
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='relative py-12 sm:py-24 bg-body overflow-hidden'>
          <img
            className='absolute top-0 left-0 w-full'
            src='casper-assets/features/triangle-top-lines.png'
            alt=''
          />
          <div className='relative container mx-auto px-4'>
            <div className='max-w-sm xs:max-w-4xl mb-14'>
              <span className='inline-flex items-center px-3.5 h-7 mb-6 text-xs font-medium text-white leading-6 bg-gradient-tag rounded-full'>
                FEATURES
              </span>
              <h2 className='font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6'>
                AI assistant engage your visitors
              </h2>
              <p className='text-xl text-gray-400'>
                We powered by state-of-the-art artificial intelligence
              </p>
            </div>
            <div className='flex flex-wrap -mx-4'>
              <div className='w-full md:w-1/2 px-4 mb-8'>
                <div className='relative h-48 xl:h-44 pt-4 md:pt-6 px-9 pb-7 rounded-3xl overflow-hidden'>
                  <img
                    className='absolute top-0 left-0 w-full h-full object-cover'
                    src='casper-assets/features/image-banner-box-1.png'
                    alt=''
                  />
                  <div className='relative'>
                    <span className='block text-6xl font-medium text-white mb-6'>
                      01
                    </span>
                    <span className='block text-2xl font-medium text-white'>
                      Intelligent Conversations
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 px-4 mb-8'>
                <div className='relative h-48 xl:h-44 pt-4 md:pt-6 px-9 pb-7 rounded-3xl overflow-hidden'>
                  <img
                    className='absolute top-0 left-0 w-full h-full object-cover'
                    src='casper-assets/features/image-banner-box-2.png'
                    alt=''
                  />
                  <div className='relative'>
                    <span className='block text-6xl font-medium text-white mb-6'>
                      02
                    </span>
                    <span className='block text-2xl font-medium text-white'>
                      Personalized Experiences
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 px-4 mb-8'>
                <div className='relative h-48 xl:h-44 pt-4 md:pt-6 px-9 pb-7 rounded-3xl overflow-hidden'>
                  <img
                    className='absolute top-0 left-0 w-full h-full object-cover'
                    src='casper-assets/features/image-banner-box-3.png'
                    alt=''
                  />
                  <div className='relative'>
                    <span className='block text-6xl font-medium text-white mb-6'>
                      03
                    </span>
                    <span className='block text-2xl font-medium text-white'>
                      24/7 Availability
                    </span>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/2 px-4 mb-8'>
                <div className='relative h-48 xl:h-44 pt-4 md:pt-6 px-9 pb-7 rounded-3xl overflow-hidden'>
                  <img
                    className='absolute top-0 left-0 w-full h-full object-cover'
                    src='casper-assets/features/image-banner-box-4.png'
                    alt=''
                  />
                  <div className='relative'>
                    <span className='block text-6xl font-medium text-white mb-6'>
                      04
                    </span>
                    <span className='block text-2xl font-medium text-white'>
                      Seamless Integration
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 lg:py-32 bg-body overflow-hidden'>
          <div className='absolute top-0 right-0 mr-40 -mt-20 w-160 h-128 bg-gradient-to-t from-blue-500 to-darkBlue-900 rounded-full filter blur-4xl' />
          <div className='absolute top-0 right-0 mt-64 mr-128 w-96 h-96 bg-gradient-to-t from-purple-600 to-darkBlue-900 rounded-full filter blur-4xl' />
          <div className='relative container mx-auto px-4'>
            <div className='max-w-xl lg:max-w-none mx-auto'>
              <div className='mb-20'>
                <h2 className='font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6'>
                  How it works
                </h2>
                <p className='text-xl text-gray-400'>
                  Mi turpis turpis in justo pellentesque id nibh praesent.
                </p>
              </div>
              <div className='relative flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 px-4 mb-16 lg:mb-0'>
                  <div className='max-w-sm pr-4 mb-8'>
                    <h3 className='font-heading text-4xl xl:text-5xl font-semibold text-gray-700'>
                      01
                    </h3>
                    <div className='my-6 h-1 w-full bg-white bg-opacity-20' />
                    <p className='text-xl text-gray-100 tracking-tight'>
                      From answering questions to providing recommendations, our
                      AI assistant will engage your visitors effortlessly.
                    </p>
                  </div>
                  <div className='max-w-sm pr-4 mb-8'>
                    <h3 className='font-heading text-4xl xl:text-5xl font-semibold text-gray-700'>
                      02
                    </h3>
                    <div className='my-6 h-1 w-full bg-white bg-opacity-20' />
                    <p className='text-xl text-gray-100 tracking-tight'>
                      By understanding your visitors' needs, our chat assistant
                      creates a unique experience
                    </p>
                  </div>
                  <div className='max-w-sm pr-4'>
                    <h3 className='font-heading text-4xl xl:text-5xl font-semibold text-gray-700'>
                      03
                    </h3>
                    <div className='my-6 h-1 w-full bg-white bg-opacity-20' />
                    <p className='text-xl text-gray-100 tracking-tight'>
                      Say goodbye to static web pages and hello to an
                      interactive, conversational platform
                    </p>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 px-4'>
                  <div className='relative flex max-w-xl mx-auto lg:mr-0 pt-16 sm:pt-24 pl-16 sm:pl-24 items-end justify-end rounded-3xl overflow-hidden'>
                    <img
                      className='absolute top-0 left-0 w-full h-full'
                      src='casper-assets/how-it-works/bg-gradient-lg.png'
                      alt=''
                    />
                    <img
                      className='relative w-full h-full rounded-tl-3xl object-cover'
                      src='casper-assets/how-it-works/box-fabric-lg.png'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 lg:py-32 bg-body overflow-hidden'>
          <div className='relative container mx-auto px-4'>
            <div className='absolute top-0 left-0 -mt-52 -ml-32 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
            <div className='absolute bottom-0 right-0 mb-40 -mr-52 w-186 h-186 bg-gradient-to-t from-blue-500 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
            <div className='relative max-w-2xl mx-auto mb-14 text-center'>
              <img
                className='hidden lg:block absolute bottom-0 left-0 -ml-48 -mb-10'
                src='casper-assets/pricing/crystal.png'
                alt=''
              />
              <span className='inline-flex items-center px-3.5 h-7 mb-4 text-xs font-medium text-white leading-6 bg-gradient-tag rounded-full'>
                PRICING PLAN
              </span>
              <h2 className='font-heading text-4xl xs:text-5xl sm:text-6xl xl:text-7xl font-medium text-white tracking-tight mb-4'>
                Choose Your Plan
              </h2>
              <p className='max-w-md mx-auto text-lg leading-snug tracking-tight text-gray-500'>
                Nunc et tellus non erat accumsan aliquam eget non mi. Integer
                fringilla pellentesque finibus.
              </p>
            </div>
            <div className='relative max-w-md lg:max-w-6xl mx-auto p-5 rounded-3xl overflow-hidden'>
              <div className='absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-500 bg-opacity-20 group-hover:bg-violet-400 group-hover:bg-opacity-100 transition duration-150' />
              <div className='relative flex flex-wrap'>
                <div className='w-full lg:w-1/2'>
                  <div className='relative pl-4 lg:pl-10 pr-4 py-10'>
                    <img
                      className='block mb-8'
                      src='casper-assets/pricing/icon-avatar-2.png'
                      alt=''
                    />
                    <div className='flex flex-wrap -mx-4 mb-8'>
                      <div className='px-4'>
                        <span className='block text-4xl font-semibold text-white'>
                          $25
                        </span>
                      </div>
                      <div className='px-4'>
                        <span className='block mb-2 text-2xl font-medium text-white'>
                          Beginner
                        </span>
                        <p className='max-w-xs text-sm text-gray-300'>
                          Simply dummy text of the printing and typesetting
                          industry.
                        </p>
                      </div>
                    </div>
                    <div>
                      <span className='block mb-4 text-lg font-medium text-white tracking-tight'>
                        What’s included:
                      </span>
                      <ul className='mb-8'>
                        <li className='flex items-center mb-4'>
                          <div className='flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                            <svg
                              width={16}
                              height={16}
                              viewBox='0 0 16 16'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-base text-white tracking-tight'>
                            Unlimited Upload Files
                          </span>
                        </li>
                        <li className='flex items-center mb-4'>
                          <div className='flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                            <svg
                              width={16}
                              height={16}
                              viewBox='0 0 16 16'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-base text-white tracking-tight'>
                            Advanced Statistic With
                          </span>
                        </li>
                        <li className='flex items-center mb-4'>
                          <div className='flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                            <svg
                              width={16}
                              height={16}
                              viewBox='0 0 16 16'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-base text-white tracking-tight'>
                            Powered by state-of-the-art AI
                          </span>
                        </li>
                        <li className='flex items-center'>
                          <div className='flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                            <svg
                              width={16}
                              height={16}
                              viewBox='0 0 16 16'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-base text-white tracking-tight'>
                            Casper will adapts to each user
                          </span>
                        </li>
                      </ul>
                      <a
                        className='group inline-flex w-full md:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-white bg-violet-500 hover:bg-black transition duration-200 rounded-full'
                        href='#'
                      >
                        <span className='mr-2'>CHOOSE PLAN</span>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z'
                            fill='currentColor'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/2'>
                  <div className='relative pl-4 lg:pl-10 pr-4 py-10 bg-white rounded-2xl'>
                    <img
                      className='block mb-8'
                      src='casper-assets/pricing/icon-avatar-1.png'
                      alt=''
                    />
                    <div className='flex flex-wrap -mx-4 mb-8'>
                      <div className='px-4'>
                        <span className='block text-4xl font-semibold text-gray-900'>
                          $25
                        </span>
                      </div>
                      <div className='px-4'>
                        <span className='block mb-2 text-2xl font-medium text-gray-900'>
                          Beginner
                        </span>
                        <p className='max-w-xs text-sm text-gray-600'>
                          Simply dummy text of the printing and typesetting
                          industry.
                        </p>
                      </div>
                    </div>
                    <div>
                      <span className='block mb-4 text-lg font-medium text-gray-900 tracking-tight'>
                        What’s included:
                      </span>
                      <ul className='mb-8'>
                        <li className='flex items-center mb-4'>
                          <div className='flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                            <svg
                              width={16}
                              height={16}
                              viewBox='0 0 16 16'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-base text-gray-900 tracking-tight'>
                            Unlimited Upload Files
                          </span>
                        </li>
                        <li className='flex items-center mb-4'>
                          <div className='flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                            <svg
                              width={16}
                              height={16}
                              viewBox='0 0 16 16'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-base text-gray-900 tracking-tight'>
                            Advanced Statistic With
                          </span>
                        </li>
                        <li className='flex items-center mb-4'>
                          <div className='flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                            <svg
                              width={16}
                              height={16}
                              viewBox='0 0 16 16'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-base text-gray-900 tracking-tight'>
                            Powered by state-of-the-art AI
                          </span>
                        </li>
                        <li className='flex items-center'>
                          <div className='flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                            <svg
                              width={16}
                              height={16}
                              viewBox='0 0 16 16'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <span className='text-base text-gray-900 tracking-tight'>
                            Casper will adapts to each user
                          </span>
                        </li>
                      </ul>
                      <a
                        className='group inline-flex w-full md:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-white bg-violet-500 hover:bg-black transition duration-200 rounded-full'
                        href='#'
                      >
                        <span className='mr-2'>CHOOSE PLAN</span>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z'
                            fill='currentColor'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-20 bg-body overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='flex flex-wrap items-center -mx-4'>
              <div className='w-full lg:w-2/3 xl:w-1/2 px-4 mb-20 lg:mb-0'>
                <div className='relative max-w-md mx-auto lg:mx-0'>
                  <h2 className='max-w-md font-heading text-4xl xs:text-5xl sm:text-6xl font-medium text-white tracking-tight mb-4'>
                    Start custom &amp; build!
                  </h2>
                  <p className='text-lg text-gray-500 tracking-tight mb-16'>
                    Nunc et tellus non erat accumsan aliquam eget non mi.
                    Integer fringilla pellentesque finibus.
                  </p>
                  <div className='flex mb-6 items-center'>
                    <svg
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7.50002 6.00002C7.20334 6.00002 6.91333 6.08799 6.66666 6.25281C6.41999 6.41763 6.22773 6.6519 6.1142 6.92599C6.00067 7.20008 5.97096 7.50168 6.02884 7.79265C6.08672 8.08362 6.22958 8.3509 6.43936 8.56068C6.64914 8.77046 6.91641 8.91332 7.20738 8.97119C7.49835 9.02907 7.79995 8.99937 8.07404 8.88584C8.34813 8.7723 8.5824 8.58005 8.74722 8.33337C8.91204 8.0867 9.00002 7.79669 9.00002 7.50002C9.00002 7.10219 8.84198 6.72066 8.56068 6.43936C8.27937 6.15805 7.89784 6.00002 7.50002 6.00002ZM21.12 10.71L12.71 2.29002C12.6166 2.19734 12.5058 2.12401 12.3839 2.07425C12.2621 2.02448 12.1316 1.99926 12 2.00002H3.00002C2.7348 2.00002 2.48045 2.10537 2.29291 2.29291C2.10537 2.48045 2.00002 2.7348 2.00002 3.00002V12C1.99926 12.1316 2.02448 12.2621 2.07425 12.3839C2.12401 12.5058 2.19734 12.6166 2.29002 12.71L10.71 21.12C11.2725 21.6818 12.035 21.9974 12.83 21.9974C13.625 21.9974 14.3875 21.6818 14.95 21.12L21.12 15C21.6818 14.4375 21.9974 13.675 21.9974 12.88C21.9974 12.085 21.6818 11.3225 21.12 10.76V10.71ZM19.71 13.53L13.53 19.7C13.3427 19.8863 13.0892 19.9908 12.825 19.9908C12.5608 19.9908 12.3074 19.8863 12.12 19.7L4.00002 11.59V4.00002H11.59L19.71 12.12C19.8027 12.2135 19.876 12.3243 19.9258 12.4461C19.9756 12.5679 20.0008 12.6984 20 12.83C19.9989 13.0924 19.8948 13.3438 19.71 13.53Z'
                        fill='#FFB11A'
                      />
                    </svg>
                    <span className='ml-3 text-3xl font-medium text-white'>
                      Only $50
                    </span>
                  </div>
                  <a
                    className='group inline-flex w-full md:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-violet-800 bg-violet-500 hover:bg-white transition duration-200 rounded-full'
                    href='#'
                  >
                    <span className='mr-2'>SEE OUR PLANS</span>
                    <svg
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z'
                        fill='currentColor'
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className='w-full lg:w-1/3 xl:w-1/2 px-4'>
                <div className='relative max-w-md mx-auto lg:max-w-none'>
                  <div className='absolute top-0 left-0 w-160 h-160 bg-gradient-to-t from-violet-900 via-darkBlue-900 rounded-full filter blur-4xl' />
                  <div className='relative'>
                    <img
                      className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
                      src='casper-assets/cta/circle-bg-large.png'
                      alt=''
                    />
                    <img
                      className='hidden sm:block absolute top-1/2 left-0 transform -translate-y-1/2 -mt-20 -ml-28 xl:-ml-0 lg:w-44 xl:w-auto'
                      src='casper-assets/cta/box-items.png'
                      alt=''
                    />
                    <img
                      className='relative block mx-auto'
                      src='casper-assets/cta/phone-robot.png'
                      alt=''
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 sm:pt-24 bg-body overflow-hidden'>
          <div className='absolute top-0 right-0 -mt-112 -mr-52 w-186 h-186 bg-gradient-to-t from-purple-500 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
          <img
            className='hidden sm:block absolute top-0 left-1/2 mt-8 lg:mt-16 md:ml-20 lg:ml-0 animate-spinStar'
            src='casper-assets/footer/blink-star.png'
            alt=''
          />
          <div className='relative container mx-auto px-4'>
            <div className='sm:flex items-center justify-between mb-16'>
              <div className='max-w-xs lg:max-w-md mb-10 sm:mb-0'>
                <h3 className='text-3xl font-medium text-white'>
                  Gain valuable insights into your users' preferences
                </h3>
              </div>
              <div className='flex flex-col lg:flex-row sm:items-end'>
                <a
                  className='inline-flex mb-4 lg:mb-0 lg:mr-4 py-2 px-3 w-36 justify-center items-center rounded-lg bg-white bg-opacity-10 hover:bg-opacity-20'
                  href='#'
                >
                  <img
                    className='block mr-2.5'
                    src='casper-assets/footer/app-store.svg'
                    alt=''
                  />
                  <div>
                    <span className='block text-xs text-gray-500'>
                      Download on
                    </span>
                    <span className='block text-sm font-medium text-white'>
                      App Store
                    </span>
                  </div>
                </a>
                <a
                  className='inline-flex py-2 px-3 w-36 justify-center items-center rounded-lg bg-white bg-opacity-10 hover:bg-opacity-20'
                  href='#'
                >
                  <img
                    className='block mr-2.5'
                    src='casper-assets/footer/google-store.svg'
                    alt=''
                  />
                  <div>
                    <span className='block text-xs text-gray-500'>
                      Download on
                    </span>
                    <span className='block text-sm font-medium text-white'>
                      Play Store
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className='pt-16 border-t-2 border-gray-800'>
              <div className='flex flex-wrap items-end -mx-4'>
                <div className='w-full md:w-2/5 px-4 mb-12'>
                  <div className='max-w-xs'>
                    <a className='inline-block mb-8' href='#'>
                      <img
                        className='block'
                        src='casper-assets/logos/casper-logo-white.svg'
                        alt=''
                      />
                    </a>
                    <p className='text-white tracking-tight mb-8'>
                      CASPER is here to revolutionize the way you interact with
                      your audience.
                    </p>
                    <span className='text-gray-600 tracking-tight'>
                      © Casper. All rights reserved
                    </span>
                  </div>
                </div>
                <div className='w-full md:w-3/5 px-4'>
                  <div className='flex flex-wrap lg:flex-nowrap -mx-4'>
                    <div className='w-auto mb-10 mr-10 xl:mr-24 px-4'>
                      <h4 className='text-xl font-medium text-white tracking-tight mb-4'>
                        Services
                      </h4>
                      <ul>
                        <li className='mb-4'>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Email Marketing
                          </a>
                        </li>
                        <li className='mb-4'>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Campaigns
                          </a>
                        </li>
                        <li className='mb-4'>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Branding
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Offline
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-auto mb-10 mr-10 xl:mr-24 px-4'>
                      <h4 className='text-xl font-medium text-white tracking-tight mb-4'>
                        About
                      </h4>
                      <ul>
                        <li className='mb-4'>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Our Story
                          </a>
                        </li>
                        <li className='mb-4'>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Benefits
                          </a>
                        </li>
                        <li className='mb-4'>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Team
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Careers
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-auto px-4'>
                      <h4 className='text-xl font-medium text-white tracking-tight mb-4'>
                        Help
                      </h4>
                      <ul>
                        <li className='mb-4'>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            FAQs
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Contact Us
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

