import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Blog1() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section>
          <nav className='px-4 md:px-10 lg:px-32 py-7 bg-body'>
            <div className='container mx-auto px-4'>
              <div className='relative flex items-center justify-between'>
                <a className='inline-block' href='#'>
                  <img
                    className='h-10'
                    src='casper-assets/logos/casper-logo-white.svg'
                    alt=''
                  />
                </a>
                <div className='flex items-center justify-end'>
                  <div className='hidden lg:block mr-10'>
                    <a
                      className='inline-flex py-2 px-4 mr-4 items-center justify-center text-sm font-medium uppercase text-white hover:text-violet-500'
                      href='#'
                    >
                      SIGN IN
                    </a>
                    <a
                      className='inline-flex h-11 py-2 px-4 items-center justify-center text-sm font-medium uppercase text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full'
                      href='#'
                    >
                      SIGN UP
                    </a>
                  </div>
                  <button className='navbar-burger text-white hover:text-violet-500'>
                    <svg
                      width={32}
                      height={32}
                      viewBox='0 0 32 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M3 16H29'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M3 8H29'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M20 24L29 24'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </nav>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-violet-900 opacity-20' />
            <nav className='relative flex flex-col py-7 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='casper-assets/logos/casper-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      FEATURED
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      SOLUTIONS
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      PRODUCTS
                    </a>
                  </li>
                  <li>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      ARTICLES
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex py-2 px-4 mb-4 items-center justify-center text-sm font-medium uppercase text-violet-900 hover:text-violet-500'
                  href='#'
                >
                  SIGN IN
                </a>
                <a
                  className='flex h-11 py-2 px-4 items-center justify-center text-sm font-medium uppercase text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full'
                  href='#'
                >
                  SIGN UP
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-body overflow-hidden'>
          <div className='relative container mx-auto px-4'>
            <div className='absolute top-0 left-0 -mt-96 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
            <div className='absolute bottom-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
            <div className='relative text-center mb-12'>
              <span className='block text-lg text-gray-500 tracking-tight mb-6'>
                Type Caption Here
              </span>
              <h2 className='font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6'>
                Blog &amp; Articles
              </h2>
            </div>
            <div className='relative max-w-sm md:max-w-6xl mx-auto'>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full md:w-1/2 px-4 mb-12 lg:mb-0'>
                  <div>
                    <img
                      className='block w-full mb-6'
                      src='casper-assets/blog/image-blog1.png'
                      alt=''
                    />
                    <h3 className='text-2xl text-white font-medium mb-4'>
                      Type you blog title here
                    </h3>
                    <p className='text-sm text-gray-300 leading-snug mb-4'>
                      Gain valuable insights into your users' preferences,
                      behavior, and frequently asked questions. Simply dummy
                      text of the printing and typesetting industry.
                    </p>
                    <a className='group flex items-center' href='#'>
                      <div className='flex items-center justify-center w-14 h-14 rounded-full border border-gray-700 group-hover:bg-violet-600 group-hover:border-violet-600 transition duration-200'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M17.92 11.62C17.8724 11.4973 17.801 11.3851 17.71 11.29L12.71 6.29C12.6168 6.19676 12.5061 6.1228 12.3842 6.07234C12.2624 6.02188 12.1319 5.99591 12 5.99591C11.7337 5.99591 11.4783 6.1017 11.29 6.29C11.1968 6.38324 11.1228 6.49393 11.0723 6.61575C11.0219 6.73758 10.9959 6.86814 10.9959 7C10.9959 7.2663 11.1017 7.5217 11.29 7.71L14.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H14.59L11.29 16.29C11.1963 16.383 11.1219 16.4936 11.0711 16.6154C11.0203 16.7373 10.9942 16.868 10.9942 17C10.9942 17.132 11.0203 17.2627 11.0711 17.3846C11.1219 17.5064 11.1963 17.617 11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L17.71 12.71C17.801 12.6149 17.8724 12.5028 17.92 12.38C18.02 12.1365 18.02 11.8635 17.92 11.62Z'
                            fill='white'
                          />
                        </svg>
                      </div>
                      <span className='ml-5 text-sm font-medium text-white'>
                        Read more
                      </span>
                    </a>
                  </div>
                </div>
                <div className='w-full md:w-1/2 px-4'>
                  <div>
                    <img
                      className='block w-full mb-6'
                      src='casper-assets/blog/image-blog2.png'
                      alt=''
                    />
                    <h3 className='text-2xl text-white font-medium mb-4'>
                      Type you blog title here
                    </h3>
                    <p className='text-sm text-gray-300 leading-snug mb-4'>
                      Gain valuable insights into your users' preferences,
                      behavior, and frequently asked questions. Simply dummy
                      text of the printing and typesetting industry.
                    </p>
                    <a className='group flex items-center' href='#'>
                      <div className='flex items-center justify-center w-14 h-14 rounded-full border border-gray-700 group-hover:bg-violet-600 group-hover:border-violet-600 transition duration-200'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M17.92 11.62C17.8724 11.4973 17.801 11.3851 17.71 11.29L12.71 6.29C12.6168 6.19676 12.5061 6.1228 12.3842 6.07234C12.2624 6.02188 12.1319 5.99591 12 5.99591C11.7337 5.99591 11.4783 6.1017 11.29 6.29C11.1968 6.38324 11.1228 6.49393 11.0723 6.61575C11.0219 6.73758 10.9959 6.86814 10.9959 7C10.9959 7.2663 11.1017 7.5217 11.29 7.71L14.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H14.59L11.29 16.29C11.1963 16.383 11.1219 16.4936 11.0711 16.6154C11.0203 16.7373 10.9942 16.868 10.9942 17C10.9942 17.132 11.0203 17.2627 11.0711 17.3846C11.1219 17.5064 11.1963 17.617 11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L17.71 12.71C17.801 12.6149 17.8724 12.5028 17.92 12.38C18.02 12.1365 18.02 11.8635 17.92 11.62Z'
                            fill='white'
                          />
                        </svg>
                      </div>
                      <span className='ml-5 text-sm font-medium text-white'>
                        Read more
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-body overflow-hidden'>
          <div className='relative container mx-auto px-4'>
            <div className='absolute top-0 left-0 -ml-60 -mt-96 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
            <div className='relative flex flex-wrap -mx-4'>
              <div className='w-full lg:w-1/2 px-4 mb-16 lg:mb-0'>
                <div className='max-w-2xl mx-auto lg:mx-0 lg:max-w-md xl:max-w-lg'>
                  <h2 className='font-heading text-4xl xs:text-5xl sm:text-6xl xl:text-7xl font-medium text-white tracking-tight mb-6'>
                    <span className='block'>Casper</span>
                    <span>is an awesome UI Library</span>
                  </h2>
                  <p className='text-3xl font-medium text-gray-600 mb-4'>
                    Arcu ultrices sit non magna enim id semper quam venenatis.
                    Mi quisque ultrices hendrerit nec aliquet integer mollis
                    faucibus morbi.
                  </p>
                  <p className='text-3xl font-medium text-gray-600'>
                    Augue justo at convallis vitae nunc maecenas est. Viverra
                    duis feugiat posuere vitae tincidunt.
                  </p>
                </div>
              </div>
              <div className='w-full lg:w-1/2 px-4'>
                <div className='max-w-2xl mx-auto lg:mx-0 lg:max-w-md xl:max-w-xl'>
                  <h3 className='text-2xl text-gray-50 font-medium mb-8'>
                    Mi quisque ultrices hendrerit nec aliquet integer mollis
                    faucibus morbi.
                  </h3>
                  <p className='text-lg tracking-tight text-gray-200 mb-4'>
                    Pellentesque ipsum nulla in eget interdum a. Neque
                    pellentesque pulvinar mauris pulvinar diam. Turpis aliquet
                    pellentesque velit vitae nisi sed morbi ultrices sed.
                    Egestas interdum elit integer nec pharetra eget blandit
                    dolor purus. A sed nisi congue morbi fermentum blandit.
                    Turpis pretium dignissim risus ultrices purus et amet netus
                    nibh.
                  </p>
                  <p className='text-lg tracking-tight text-gray-200 mb-10'>
                    Vestibulum est ante in congue a fusce nunc. At tristique
                    donec nisi viverra vulputate blandit orci at lectus. Morbi
                    cras urna congue ornare. Mi magna vestibulum tortor id nec
                    tortor non. Enim orci lorem egestas sed morbi dui mauris
                    etiam nulla. Euismod cursus viverra ut ac eu sit quam amet
                    tempor. Id in suspendisse nam sit vitae ullamcorper mollis
                    et ut.
                  </p>
                  <img
                    className='block w-full'
                    src='casper-assets/content/image-robot.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 sm:pt-24 bg-body overflow-hidden'>
          <div className='absolute top-0 right-0 -mt-40 -mr-52 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
          <img
            className='absolute top-0 right-0 w-40 md:w-52 xl:w-auto mt-16 xl:mt-24 lg:mr-24'
            src='casper-assets/footer/robot.png'
            alt=''
          />
          <div className='relative container mx-auto px-4'>
            <div className='max-w-4xl xl:max-w-6xl mb-20'>
              <img
                className='block mb-20'
                src='casper-assets/footer/logo-c-only.svg'
                alt=''
              />
              <h3 className='font-heading text-4xl xs:text-5xl sm:text-6xl font-medium text-white tracking-tight mb-20'>
                Gain valuable insights into your users' preferences
              </h3>
              <div className='-mb-6'>
                <div className='inline-flex mb-6 mr-12 items-center'>
                  <div className='flex justify-center items-center w-12 h-12 rounded-full bg-yellow-600'>
                    <svg
                      width={24}
                      height={25}
                      viewBox='0 0 24 25'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M21 10.5C21 17.5 12 23.5 12 23.5C12 23.5 3 17.5 3 10.5C3 8.11305 3.94821 5.82387 5.63604 4.13604C7.32387 2.44821 9.61305 1.5 12 1.5C14.3869 1.5 16.6761 2.44821 18.364 4.13604C20.0518 5.82387 21 8.11305 21 10.5Z'
                        stroke='black'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M12 13.5C13.6569 13.5 15 12.1569 15 10.5C15 8.84315 13.6569 7.5 12 7.5C10.3431 7.5 9 8.84315 9 10.5C9 12.1569 10.3431 13.5 12 13.5Z'
                        stroke='black'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  <div className='ml-3'>
                    <span className='block text-white tracking-tight'>
                      1929, Bancangan, Sambit,
                    </span>
                    <span className='block text-white tracking-tight'>
                      Wakanda Kidul
                    </span>
                  </div>
                </div>
                <div className='inline-flex mb-6 items-center'>
                  <div className='flex justify-center items-center w-12 h-12 rounded-full bg-sky-600'>
                    <svg
                      width={24}
                      height={25}
                      viewBox='0 0 24 25'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 9.5L11.38 12.99C11.5565 13.1295 11.775 13.2054 12 13.2054C12.225 13.2054 12.4435 13.1295 12.62 12.99L17 9.5M20 19.5H4C3.73478 19.5 3.48043 19.3946 3.29289 19.2071C3.10536 19.0196 3 18.7652 3 18.5V6.5C3 6.23478 3.10536 5.98043 3.29289 5.79289C3.48043 5.60536 3.73478 5.5 4 5.5H20C20.2652 5.5 20.5196 5.60536 20.7071 5.79289C20.8946 5.98043 21 6.23478 21 6.5V18.5C21 18.7652 20.8946 19.0196 20.7071 19.2071C20.5196 19.3946 20.2652 19.5 20 19.5Z'
                        stroke='black'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </div>
                  <div className='ml-3'>
                    <span className='block text-white tracking-tight'>
                      hello@yourdomain.com
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-10 border-t border-gray-800'>
              <div className='md:flex items-center justify-between'>
                <div className='mb-6 md:mb-0'>
                  <span className='text-gray-600 tracking-tight'>
                    © Casper. All rights reserved
                  </span>
                </div>
                <div>
                  <a
                    className='inline-block mr-10 text-white hover:underline tracking-tight'
                    href='#'
                  >
                    Terms &amp; Conditions
                  </a>
                  <a
                    className='inline-block text-white hover:underline tracking-tight'
                    href='#'
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

