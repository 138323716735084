import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index4() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='relative pb-20 bg-body overflow-hidden'>
          <nav className='relative z-10 py-7'>
            <div className='container mx-auto px-4'>
              <div className='relative flex items-center justify-between'>
                <a className='inline-block' href='#'>
                  <img
                    className='h-10'
                    src='casper-assets/logos/casper-logo-white.svg'
                    alt=''
                  />
                </a>
                <div className='flex items-center justify-end'>
                  <div className='hidden lg:block mr-10'>
                    <a
                      className='inline-flex py-2 px-4 mr-4 items-center justify-center text-sm font-medium uppercase text-white hover:text-violet-500'
                      href='#'
                    >
                      SIGN IN
                    </a>
                    <a
                      className='inline-flex h-11 py-2 px-4 items-center justify-center text-sm font-medium uppercase text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full'
                      href='#'
                    >
                      SIGN UP
                    </a>
                  </div>
                  <button className='navbar-burger text-white hover:text-violet-500'>
                    <svg
                      width={32}
                      height={32}
                      viewBox='0 0 32 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M3 16H29'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M3 8H29'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M20 24L29 24'
                        stroke='currentColor'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </nav>
          <img
            className='absolute top-0 right-0'
            src='casper-assets/headers/circle-double-element-dark.svg'
            alt=''
          />
          <div className='relative pt-20'>
            <img
              className='hidden lg:block absolute top-0 left-1/2 -ml-8 -mt-6 animate-spinStar z-10'
              src='casper-assets/headers/blink.png'
              alt=''
            />
            <div className='absolute top-0 left-1/2 transform -translate-x-1/2 -mt-20 w-160 h-160 bg-gradient-to-t from-purple-700 to-darkBlue-900 rounded-full filter blur-4xl' />
            <div className='relative container mx-auto px-4'>
              <div className='flex flex-wrap -mx-4 mb-9'>
                <div className='w-full lg:w-1/2 px-4 mb-8 lg:mb-0'>
                  <div className='relative max-w-md lg:max-w-none'>
                    <h1 className='font-heading text-4xl xs:text-5xl md:text-7xl font-semibold text-white leading-none'>
                      INTRODUCING CASPER AI
                    </h1>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 px-4'>
                  <div className='max-w-sm lg:ml-auto'>
                    <p className='text-2xl text-gray-400 mb-8'>
                      <span className='block'>Welcome to ChatBotX -</span>
                      <span className='block'>
                        Your Intelligent AI Chat Assistant!
                      </span>
                    </p>
                    <a
                      className='group inline-flex h-14 px-7 items-center justify-center text-base font-medium text-violet-900 hover:text-white bg-white hover:bg-violet-600 border border-gray-200 hover:border-violet-600 transition duration-200 rounded-full'
                      href='#'
                    >
                      <span className='mr-2'>TRY IT FOR FREE</span>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z'
                          fill='currentColor'
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-2/3 px-4 mb-8 lg:mb-0'>
                  <img
                    className='block w-full'
                    src='casper-assets/headers/photo-horizontal-2.png'
                    alt=''
                  />
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <img
                    className='block w-full'
                    src='casper-assets/headers/photo-cosmic-1.png'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50'>
            <div className='navbar-backdrop fixed inset-0 bg-violet-900 opacity-20' />
            <nav className='relative flex flex-col py-7 px-10 w-full h-full bg-white overflow-y-auto'>
              <div className='flex mb-auto items-center'>
                <a className='inline-block mr-auto' href='#'>
                  <img
                    className='h-10'
                    src='casper-assets/logos/casper-logo.svg'
                    alt=''
                  />
                </a>
                <a className='navbar-close' href='#'>
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6 18L18 6M6 6L18 18'
                      stroke='#111827'
                      strokeWidth={2}
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </a>
              </div>
              <div className='py-12 mb-auto'>
                <ul className='flex-col'>
                  <li className='mb-6'>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      FEATURED
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      SOLUTIONS
                    </a>
                  </li>
                  <li className='mb-6'>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      PRODUCTS
                    </a>
                  </li>
                  <li>
                    <a
                      className='inline-block text-base text-black font-medium uppercase'
                      href='#'
                    >
                      ARTICLES
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className='flex py-2 px-4 mb-4 items-center justify-center text-sm font-medium uppercase text-violet-900 hover:text-violet-500'
                  href='#'
                >
                  SIGN IN
                </a>
                <a
                  className='flex h-11 py-2 px-4 items-center justify-center text-sm font-medium uppercase text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full'
                  href='#'
                >
                  SIGN UP
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-body overflow-hidden'>
          <img
            className='absolute bottom-0 right-0'
            src='casper-assets/features/double-line-circle.svg'
            alt=''
          />
          <img
            className='hidden md:block z-10 absolute top-0 left-0 mt-24 ml-4 lg:ml-24 xl:ml-64 animate-spinStar'
            src='casper-assets/features/blink-star.png'
            alt=''
          />
          <div className='relative container mx-auto px-4'>
            <div className='absolute bottom-0 left-0 -mb-20 w-160 h-160 bg-gradient-to-t from-purple-700 to-darkBlue-900 rounded-full filter blur-4xl' />
            <div className='absolute bottom-0 right-0 -mb-20 w-148 h-148 bg-gradient-to-t from-violet-900 to-darkBlue-900 rounded-full filter blur-4xl' />
            <div className='relative'>
              <div className='max-w-xl mx-auto mb-16 text-center'>
                <h2 className='font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6'>
                  Intelligent Conversations
                </h2>
                <p className='text-xl text-gray-400'>
                  Casper is powered by state-of-the-art artificial intelligence
                  and ensuring natural and meaningful interactions
                </p>
              </div>
              <div className='flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/3 px-4 mb-8 lg:mb-0'>
                  <div className='max-w-md mx-auto h-full p-8 rounded-3xl shadow-box-violet overflow-hidden bg-white bg-opacity-10'>
                    <div className='flex mb-12 items-center'>
                      <div className='flex-shrink-0 flex mr-4 items-center justify-center w-16 h-16 rounded-full bg-green-500'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M11.6799 16.9298L15.7499 20.9998L17.5399 19.2098C17.6441 19.106 17.7241 18.9805 17.7743 18.8422C17.8244 18.7039 17.8434 18.5563 17.8299 18.4098L17.1799 11.2998'
                            stroke='white'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M9.36994 4.71L7.99994 6.13M9.36994 17.46C9.4629 17.5537 9.5735 17.6281 9.69536 17.6789C9.81722 17.7297 9.94793 17.7558 10.0799 17.7558C10.212 17.7558 10.3427 17.7297 10.4645 17.6789C10.5864 17.6281 10.697 17.5537 10.7899 17.46L18.6999 9.55C20.1713 8.07754 20.9984 6.08157 20.9999 4C20.9999 3.73478 20.8946 3.48043 20.707 3.29289C20.5195 3.10536 20.2652 3 19.9999 3C17.9184 3.00151 15.9224 3.82867 14.4499 5.3L6.53994 13.21C6.44621 13.303 6.37182 13.4136 6.32105 13.5354C6.27028 13.6573 6.24414 13.788 6.24414 13.92C6.24414 14.052 6.27028 14.1827 6.32105 14.3046C6.37182 14.4264 6.44621 14.537 6.53994 14.63L9.36994 17.46ZM17.8699 16L19.2899 14.59L17.8699 16Z'
                            stroke='white'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M12.75 6.82022L5.59 6.17022C5.44353 6.15675 5.2959 6.17575 5.15761 6.22588C5.01933 6.27601 4.89381 6.35602 4.79 6.46022L3 8.25022L7.09 12.3402'
                            stroke='white'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                      <div>
                        <h4 className='text-2xl font-medium text-white leading-tight'>
                          <span className='block'>Seamless</span>
                          <span>Integration</span>
                        </h4>
                      </div>
                    </div>
                    <p className='text-xl text-gray-400'>
                      Powered by state-of-the-art artificial intelligence,
                      ensuring natural and meaningful
                    </p>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4 mb-8 lg:mb-0'>
                  <div className='max-w-md mx-auto h-full p-8 rounded-3xl shadow-box-orange overflow-hidden bg-white bg-opacity-10'>
                    <div className='flex mb-12 items-center'>
                      <div className='flex-shrink-0 flex mr-4 items-center justify-center w-16 h-16 rounded-full bg-yellow-700'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3 3V2C2.44772 2 2 2.44772 2 3H3ZM18.293 14.293L17.3818 13.8809C17.2104 14.2601 17.2916 14.7058 17.5859 15.0001L18.293 14.293ZM21 17L21.7071 17.7071C22.0976 17.3166 22.0976 16.6834 21.7071 16.2929L21 17ZM17 21L16.2929 21.7071C16.6834 22.0976 17.3166 22.0976 17.7071 21.7071L17 21ZM14.293 18.293L15.0001 17.5859C14.7058 17.2916 14.2601 17.2104 13.8809 17.3818L14.293 18.293ZM11 2H3V4H11V2ZM20 11C20 6.02944 15.9706 2 11 2V4C14.866 4 18 7.13401 18 11H20ZM19.2041 14.7051C19.7157 13.5738 20 12.3188 20 11H18C18 12.029 17.7786 13.0036 17.3818 13.8809L19.2041 14.7051ZM17.5859 15.0001L20.2929 17.7071L21.7071 16.2929L19.0001 13.5859L17.5859 15.0001ZM20.2929 16.2929L16.2929 20.2929L17.7071 21.7071L21.7071 17.7071L20.2929 16.2929ZM17.7071 20.2929L15.0001 17.5859L13.5859 19.0001L16.2929 21.7071L17.7071 20.2929ZM11 20C12.3188 20 13.5738 19.7157 14.7051 19.2041L13.8809 17.3818C13.0036 17.7786 12.029 18 11 18V20ZM2 11C2 15.9706 6.02944 20 11 20V18C7.13401 18 4 14.866 4 11H2ZM2 3V11H4V3H2Z'
                            fill='white'
                          />
                          <ellipse
                            cx={11}
                            cy={11}
                            rx={2}
                            ry={2}
                            transform='rotate(-180 11 11)'
                            fill='white'
                          />
                          <path
                            d='M3 3L11 11'
                            stroke='white'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                      <div>
                        <h4 className='text-2xl font-medium text-white leading-tight'>
                          <span className='block'>Intelligent</span>
                          <span>Conversations</span>
                        </h4>
                      </div>
                    </div>
                    <p className='text-xl text-gray-400'>
                      Whether you're a tech guru or a beginner, you'll have your
                      AI chat assistant up
                    </p>
                  </div>
                </div>
                <div className='w-full lg:w-1/3 px-4'>
                  <div className='max-w-md mx-auto h-full p-8 rounded-3xl shadow-box-green overflow-hidden bg-white bg-opacity-10'>
                    <div className='flex mb-12 items-center'>
                      <div className='flex-shrink-0 flex mr-4 items-center justify-center w-16 h-16 rounded-full bg-sky-500'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M6 9C6 5.68629 8.68629 3 12 3V3C15.3137 3 18 5.68629 18 9V15C18 18.3137 15.3137 21 12 21V21C8.68629 21 6 18.3137 6 15V9Z'
                            stroke='white'
                            strokeWidth={2}
                            strokeLinejoin='round'
                          />
                          <path
                            d='M12 7L12 11'
                            stroke='white'
                            strokeWidth={2}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </div>
                      <div>
                        <h4 className='text-2xl font-medium text-white leading-tight'>
                          <span className='block'>Analytics and</span>
                          <span>Insights:</span>
                        </h4>
                      </div>
                    </div>
                    <p className='text-xl text-gray-400'>
                      Gain valuable insights into your users' preferences,
                      behavior, and frequently asked questions
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 lg:py-32 bg-body overflow-hidden'>
          <div className='relative container mx-auto px-4'>
            <div className='absolute top-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-900 to-darkBlue-900 rounded-full filter blur-4xl' />
            <div className='relative max-w-md md:max-w-8xl mx-auto'>
              <div className='text-center mb-14'>
                <h2 className='font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6'>
                  How Does Casper Work
                </h2>
                <p className='text-xl text-gray-400'>
                  Mi turpis turpis in justo pellentesque id nibh praesent.
                </p>
              </div>
              <div className='flex flex-wrap items-center -mx-4 mb-16'>
                <div className='w-full md:w-1/2 px-4 mb-8 md:mb-0'>
                  <div className='relative flex max-w-md pt-12 lg:pt-20 pl-12 lg:pl-20 items-end justify-end rounded-3xl overflow-hidden'>
                    <img
                      className='absolute top-0 left-0 w-full h-full'
                      src='casper-assets/how-it-works/bg-square-gradient.png'
                      alt=''
                    />
                    <img
                      className='relative w-87 h-87 rounded-tl-3xl object-cover'
                      src='casper-assets/how-it-works/image-square-sm-1.png'
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full md:w-1/2 px-4'>
                  <div className='max-w-lg'>
                    <h3 className='font-heading text-4xl lg:text-5xl font-semibold text-white'>
                      01. Upload
                    </h3>
                    <div className='my-6 h-1 w-full bg-white bg-opacity-20' />
                    <p className='text-xl text-white tracking-tight'>
                      Our user-friendly interface allows for quick setup, and
                      our dedicated support team is ready to assist you every
                      step of the way.
                    </p>
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap items-center -mx-4 mb-16'>
                <div className='w-full md:w-1/2 px-4 order-last md:order-first'>
                  <div className='max-w-lg'>
                    <h3 className='font-heading text-4xl lg:text-5xl font-semibold text-white'>
                      02. Auto Generation
                    </h3>
                    <div className='my-6 h-1 w-full bg-white bg-opacity-20' />
                    <p className='text-xl text-white tracking-tight'>
                      From answering questions to providing recommendations, our
                      AI assistant will engage your visitors effortlessly.
                    </p>
                  </div>
                </div>
                <div className='w-full md:w-1/2 px-4 mb-8 md:mb-0'>
                  <div className='relative flex max-w-md pt-12 lg:pt-20 pl-12 lg:pl-20 md:ml-auto items-end justify-end rounded-3xl overflow-hidden'>
                    <img
                      className='absolute top-0 left-0 w-full h-full'
                      src='casper-assets/how-it-works/bg-square-gradient.png'
                      alt=''
                    />
                    <img
                      className='relative w-87 h-87 rounded-tl-3xl object-cover'
                      src='casper-assets/how-it-works/image-square-sm-2.png'
                      alt=''
                    />
                  </div>
                </div>
              </div>
              <div className='flex flex-wrap items-center -mx-4'>
                <div className='w-full md:w-1/2 px-4 mb-8 md:mb-0'>
                  <div className='relative flex max-w-md pt-12 lg:pt-20 pl-12 lg:pl-20 items-end justify-end rounded-3xl overflow-hidden'>
                    <img
                      className='absolute top-0 left-0 w-full h-full'
                      src='casper-assets/how-it-works/bg-square-gradient.png'
                      alt=''
                    />
                    <img
                      className='relative w-87 h-87 rounded-tl-3xl object-cover'
                      src='casper-assets/how-it-works/image-square-sm-3.png'
                      alt=''
                    />
                  </div>
                </div>
                <div className='w-full md:w-1/2 px-4'>
                  <div className='max-w-lg'>
                    <h3 className='font-heading text-4xl lg:text-5xl font-semibold text-white'>
                      03. Select &amp; Send
                    </h3>
                    <div className='my-6 h-1 w-full bg-white bg-opacity-20' />
                    <p className='text-xl text-white tracking-tight'>
                      Our user-friendly interface allows for quick setup, and
                      our dedicated support team is ready to assist you every
                      step of the way.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-body overflow-hidden'>
          <div className='relative container mx-auto px-4'>
            <img
              className='absolute top-0 right-0 animate-spinStar z-10'
              src='casper-assets/services/blink-star.png'
              alt=''
            />
            <div className='absolute top-0 right-0 -mr-52 -mt-52 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
            <div className='relative max-w-lg lg:max-w-4xl mx-auto lg:mx-0 mb-14'>
              <h2 className='font-heading text-4xl xs:text-5xl sm:text-6xl lg:text-7xl font-medium text-white tracking-tight mb-6'>
                Look no further! Casper is here to revolutionize
              </h2>
              <p className='max-w-xl text-xl text-gray-500 tracking-tight'>
                Casper is powered by state-of-the-art artificial intelligence
                and ensuring natural and meaningful interactions
              </p>
            </div>
            <div className='max-w-lg mx-auto lg:max-w-none'>
              <div className='relative flex flex-wrap -mx-4'>
                <div className='w-full lg:w-1/2 px-4 mb-8 lg:mb-0'>
                  <div className='relative h-full p-10 rounded-3xl overflow-hidden'>
                    <img
                      className='absolute top-0 left-0 w-full h-full object-cover'
                      src='casper-assets/services/bg-gradient-square.png'
                      alt=''
                    />
                    <div className='relative flex flex-col h-full items-start max-w-md'>
                      <img
                        className='block w-18 mb-16'
                        src='casper-assets/services/icon-white-1.png'
                        alt=''
                      />
                      <h4 className='text-3xl font-medium mt-auto mb-4'>
                        Intelligent Conversations
                      </h4>
                      <p className='leading-snug mb-4'>
                        ChatBotX is powered by state-of-the-art artificial
                        intelligence, ensuring natural interactions with your
                        users
                      </p>
                      <a
                        className='group inline-flex w-full sm:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-violet-900 bg-violet-600 hover:bg-white transition duration-200 rounded-full'
                        href='#'
                      >
                        <span className='mr-2'>SEE DETAILS</span>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z'
                            fill='currentColor'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-full lg:w-1/2 px-4'>
                  <div className='p-10 mb-8 rounded-3xl shadow-box-violet overflow-hidden bg-white bg-opacity-10'>
                    <div className='relative flex flex-col h-full items-start max-w-md'>
                      <img
                        className='block w-18 mb-16'
                        src='casper-assets/services/icon-white-2.png'
                        alt=''
                      />
                      <h4 className='text-3xl font-medium text-white mt-auto mb-4'>
                        Intelligent Conversations
                      </h4>
                      <p className='leading-snug text-white mb-4'>
                        ChatBotX is powered by state-of-the-art artificial
                        intelligence, ensuring natural interactions with your
                        users
                      </p>
                      <a
                        className='group inline-flex w-full sm:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-violet-900 bg-violet-600 hover:bg-white transition duration-200 rounded-full'
                        href='#'
                      >
                        <span className='mr-2'>SEE DETAILS</span>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z'
                            fill='currentColor'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div className='p-6 rounded-3xl shadow-box-violet overflow-hidden bg-white bg-opacity-10'>
                    <div className='relative sm:flex items-center'>
                      <img
                        className='block w-18'
                        src='casper-assets/services/icon-white-3.png'
                        alt=''
                      />
                      <div className='my-8 sm:my-0 sm:ml-9'>
                        <h4 className='text-3xl font-medium text-white leading-8'>
                          <span className='block'>Seamless</span>
                          <span className='block'>Integration</span>
                        </h4>
                      </div>
                      <a
                        className='group inline-flex ml-auto h-14 w-14 items-center justify-center text-black hover:text-violet-900 bg-violet-600 hover:bg-white transition duration-200 rounded-full'
                        href='#'
                      >
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z'
                            fill='currentColor'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 lg:py-32 bg-body overflow-hidden'>
          <div className='relative container mx-auto px-4'>
            <div className='absolute top-0 left-0 -mt-64 -ml-32 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
            <div className='absolute bottom-0 right-0 mb-20 -mr-52 w-186 h-186 bg-gradient-to-t from-blue-500 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
            <div className='relative max-w-2xl mx-auto mb-14 text-center'>
              <img
                className='hidden md:block absolute bottom-0 left-0 -ml-40 lg:-ml-80 xl:-ml-112 lg:-mb-24 w-52 lg:w-auto animate-movePlanet'
                src='casper-assets/pricing/chip-robot.png'
                alt=''
              />
              <span className='inline-flex items-center px-3.5 h-7 mb-4 text-xs font-medium text-white leading-6 bg-gradient-tag rounded-full'>
                PRICING PLAN
              </span>
              <h2 className='font-heading text-4xl xs:text-5xl sm:text-6xl xl:text-7xl font-medium text-white tracking-tight mb-4'>
                Choose Your Plan
              </h2>
              <p className='max-w-md mx-auto text-lg text-gray-500 tracking-tight'>
                Nunc et tellus non erat accumsan aliquam eget non mi. Integer
                fringilla pellentesque finibus.
              </p>
            </div>
            <div className='px-4 overflow-x-auto'>
              <table className='table-auto text-white w-full min-w-max'>
                <thead>
                  <tr className='border-b border-gray-100 border-opacity-20'>
                    <th className='pb-12 pr-4' />
                    <th className='pb-12 px-4'>
                      <div className='relative w-64 p-6 rounded-2xl overflow-hidden'>
                        <div className='absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-500 bg-opacity-20 group-hover:bg-violet-400 group-hover:bg-opacity-100 transition duration-150' />
                        <div className='relative'>
                          <img
                            className='block w-18 h-18 mb-6'
                            src='casper-assets/pricing/avatar-pricing1.png'
                            alt=''
                          />
                          <div className='flex items-end'>
                            <span className='text-5xl font-semibold text-white leading-10'>
                              $50
                            </span>
                            <span className='inline-block ml-px text-base font-medium text-tracking text-gray-500 opacity-70'>
                              /month
                            </span>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className='pb-12 px-4'>
                      <div className='relative w-64 p-6 rounded-2xl overflow-hidden'>
                        <img
                          className='absolute top-0 left-0 w-full h-full'
                          src='casper-assets/pricing/bg-gradient-square.png'
                          alt=''
                        />
                        <div className='relative'>
                          <img
                            className='block w-18 h-18 mb-6'
                            src='casper-assets/pricing/avatar-pricing2.png'
                            alt=''
                          />
                          <div className='flex items-end'>
                            <span className='text-5xl font-semibold text-black leading-10'>
                              $100
                            </span>
                            <span className='inline-block ml-px text-base font-medium text-tracking text-gray-500 opacity-70'>
                              /month
                            </span>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className='pb-12 pl-4'>
                      <div className='relative w-64 p-6 rounded-2xl overflow-hidden'>
                        <div className='absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-500 bg-opacity-20 group-hover:bg-violet-400 group-hover:bg-opacity-100 transition duration-150' />
                        <div className='relative'>
                          <img
                            className='block w-18 h-18 mb-6'
                            src='casper-assets/pricing/avatar-pricing3.png'
                            alt=''
                          />
                          <div className='flex items-end'>
                            <span className='text-5xl font-semibold text-white leading-10'>
                              $150
                            </span>
                            <span className='inline-block ml-px text-base font-medium text-tracking text-gray-500 opacity-70'>
                              /month
                            </span>
                          </div>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='p-0'>
                      <div className='flex items-center py-4 px-8 h-18 text-lg font-medium tracking-tight'>
                        <span className='relative'>Unlimited Upload Files</span>
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='flex items-center justify-center h-18'>
                        <div className='flex items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                              fill='black'
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='flex items-center justify-center h-18'>
                        <div className='flex items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                              fill='black'
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='flex items-center justify-center h-18'>
                        <div className='flex items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                              fill='black'
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='p-0'>
                      <div className='relative flex items-center py-4 px-8 h-18 text-lg font-medium tracking-tight'>
                        <div className='absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-500 bg-opacity-20 rounded-l-md' />
                        <span className='relative'>Duration</span>
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='relative flex items-center justify-center py-4 px-8 h-18 tracking-tight'>
                        <div className='absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-500 bg-opacity-20' />
                        <span className='relative text-lg tracking-tight text-gray-200'>
                          1 month
                        </span>
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='relative flex items-center justify-center py-4 px-8 h-18 tracking-tight'>
                        <div className='absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-500 bg-opacity-20' />
                        <span className='relative text-lg tracking-tight text-gray-200'>
                          3 month
                        </span>
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='relative flex items-center justify-center py-4 px-8 h-18 tracking-tight'>
                        <div className='absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-500 bg-opacity-20 rounded-r-md' />
                        <span className='relative text-lg tracking-tight text-gray-200'>
                          6 month
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='p-0'>
                      <div className='flex items-center py-4 px-8 h-18 text-lg font-medium tracking-tight'>
                        <span className='relative'>
                          Advanced Statistic With
                        </span>
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='flex items-center justify-center h-18'>
                        <div className='flex items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                              fill='black'
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='flex items-center justify-center h-18'>
                        <div className='flex items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                              fill='black'
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='flex items-center justify-center h-18'>
                        <div className='flex items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                              fill='black'
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='p-0'>
                      <div className='relative flex items-center py-4 px-8 h-18 text-lg font-medium tracking-tight'>
                        <div className='absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-500 bg-opacity-20 rounded-l-md' />
                        <span className='relative'>
                          Powered by state-of-the-art AI
                        </span>
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='relative flex items-center justify-center py-4 px-8 h-18 text-lg font-medium tracking-tight'>
                        <div className='absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-500 bg-opacity-20' />
                        <div className='relative flex items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                              fill='black'
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='relative flex items-center justify-center py-4 px-8 h-18 text-lg font-medium tracking-tight'>
                        <div className='absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-500 bg-opacity-20' />
                        <div className='relative flex items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                              fill='black'
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='relative flex items-center justify-center py-4 px-8 h-18 text-lg font-medium tracking-tight'>
                        <div className='absolute top-0 left-0 w-full h-full backdrop-filter backdrop-blur-md bg-gray-500 bg-opacity-20 rounded-r-md' />
                        <div className='relative flex items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                              fill='black'
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='p-0'>
                      <div className='flex items-center py-4 px-8 h-18 text-lg font-medium tracking-tight'>
                        <span className='relative'>
                          Casper will adapts to each user
                        </span>
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='flex items-center justify-center h-18'>
                        <div className='flex items-center justify-center w-5 h-5 rounded-full bg-red-600'>
                          <svg
                            width={12}
                            height={12}
                            viewBox='0 0 12 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M9 3L3 9'
                              stroke='black'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M3 3L9 9'
                              stroke='black'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='flex items-center justify-center h-18'>
                        <div className='flex items-center justify-center w-5 h-5 rounded-full bg-red-600'>
                          <svg
                            width={12}
                            height={12}
                            viewBox='0 0 12 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M9 3L3 9'
                              stroke='black'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                            <path
                              d='M3 3L9 9'
                              stroke='black'
                              strokeWidth='1.5'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='flex items-center justify-center h-18'>
                        <div className='flex items-center justify-center w-5 h-5 rounded-full bg-green-600'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z'
                              fill='black'
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <section className='relative py-12 sm:py-24 bg-body overflow-hidden'>
          <div className='relative container mx-auto px-4'>
            <div className='flex flex-wrap items-center -mx-4'>
              <div className='w-full xl:w-2/5 px-4 mb-20 xl:mb-0'>
                <div className='relative max-w-xl xl:max-w-md mx-auto xl:mx-0 md:pb-52'>
                  <img
                    className='mb-16 animate-spinStar'
                    src='casper-assets/testimonials/blink-star.png'
                    alt=''
                  />
                  <div className='relative z-10'>
                    <h2 className='font-heading text-4xl xs:text-5xl sm:text-6xl lg:text-7xl font-medium text-white tracking-tight mb-8'>
                      Trusted by more than 100k people
                    </h2>
                    <p className='text-xl text-gray-500 leading-relaxed mb-8'>
                      Casper is powered by state-of-the-art artificial
                      intelligence and ensuring natural and meaningful
                      interactions
                    </p>
                    <a
                      className='group inline-flex h-14 px-7 items-center justify-center text-base font-medium text-white hover:text-violet-500 bg-transparent hover:bg-white border-2 border-white transition duration-200 rounded-full'
                      href='#'
                    >
                      <span className='mr-2'>SEE ALL TESTIMONIALS</span>
                      <span>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                  <img
                    className='block md:absolute bottom-0 right-0 md:-mr-40'
                    src='casper-assets/testimonials/robot-side.png'
                    alt=''
                  />
                </div>
              </div>
              <div className='relative w-full xl:w-3/5 px-4'>
                <div className='absolute top-0 right-0 w-160 h-160 -mr-10 bg-gradient-to-t from-purple-700 to-darkBlue-900 rounded-full filter blur-4xl' />
                <div className='relative max-w-2xl mx-auto xl:mr-0'>
                  <div className='hidden md:block absolute bottom-0 left-0 md:ml-20 w-full md:max-w-xl h-40 bg-gradient-to-t from-darkBlue-900 to-transparent' />
                  <div className='md:max-w-xl pl-8 pr-14 py-6 mb-8 md:h-40 rounded-3xl shadow-box-violet overflow-hidden bg-white bg-opacity-10'>
                    <div className='flex items-start'>
                      <img
                        className='flex-shrink-0 block w-14 h-14 rounded-full'
                        src='casper-assets/testimonials/avatar-2.png'
                        alt=''
                      />
                      <div className='ml-5'>
                        <div className='md:flex mb-3 items-center justify-between'>
                          <div>
                            <span className='block text-xl text-white font-medium mb-1'>
                              Sulis Azalea
                            </span>
                            <span className='block text-sm text-gray-500'>
                              CEO of Sans Design
                            </span>
                          </div>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z'
                                fill='#FFD33C'
                              />
                            </svg>
                            <span className='ml-2.5 text-xl font-medium text-white'>
                              5
                            </span>
                          </div>
                        </div>
                        <p className='text-lg text-white'>
                          It was a pleasure working with the Saturn. They
                          understood the brief correctly
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='relative md:max-w-xl md:-ml-20 pl-8 pr-14 py-6 mb-8 md:h-40 rounded-3xl overflow-hidden'>
                    <img
                      className='absolute top-0 left-0 w-full h-full'
                      src='casper-assets/testimonials/bg-gradient-color.png'
                      alt=''
                    />
                    <div className='relative flex items-start'>
                      <img
                        className='flex-shrink-0 block w-14 h-14 rounded-full'
                        src='casper-assets/testimonials/avatar-1.png'
                        alt=''
                      />
                      <div className='ml-5'>
                        <div className='md:flex mb-3 items-center justify-between'>
                          <div>
                            <span className='block text-xl text-black font-medium mb-1'>
                              Beby Jovanca
                            </span>
                            <span className='block text-sm text-gray-600'>
                              CEO of Coca Soft
                            </span>
                          </div>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z'
                                fill='#FFD33C'
                              />
                            </svg>
                            <span className='ml-2.5 text-xl font-medium text-black'>
                              5
                            </span>
                          </div>
                        </div>
                        <p className='text-lg text-black'>
                          It was a pleasure working with the Saturn. They
                          understood the brief correctly
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='md:max-w-xl pl-8 pr-14 py-6 mb-8 md:h-40 rounded-3xl shadow-box-violet overflow-hidden bg-white bg-opacity-10'>
                    <div className='flex items-start'>
                      <img
                        className='flex-shrink-0 block w-14 h-14 rounded-full'
                        src='casper-assets/testimonials/avatar-2.png'
                        alt=''
                      />
                      <div className='ml-5'>
                        <div className='md:flex mb-3 items-center justify-between'>
                          <div>
                            <span className='block text-xl text-white font-medium mb-1'>
                              Jenifer Donn
                            </span>
                            <span className='block text-sm text-gray-500'>
                              CEO of Eco Tech
                            </span>
                          </div>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z'
                                fill='#FFD33C'
                              />
                            </svg>
                            <span className='ml-2.5 text-xl font-medium text-white'>
                              5
                            </span>
                          </div>
                        </div>
                        <p className='text-lg text-white'>
                          It was a pleasure working with the Saturn. They
                          understood the brief correctly
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='md:max-w-xl md:ml-20 pl-8 pr-14 py-6 md:h-40 rounded-3xl shadow-box-violet overflow-hidden bg-white bg-opacity-10'>
                    <div className='flex items-start'>
                      <img
                        className='flex-shrink-0 block w-14 h-14 rounded-full'
                        src='casper-assets/testimonials/avatar-1.png'
                        alt=''
                      />
                      <div className='ml-5'>
                        <div className='md:flex mb-3 items-center justify-between'>
                          <div>
                            <span className='block text-xl text-white font-medium mb-1'>
                              Sulis Azalea
                            </span>
                            <span className='block text-sm text-gray-500'>
                              CEO of Sans Design
                            </span>
                          </div>
                          <div className='flex items-center'>
                            <svg
                              width={20}
                              height={20}
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z'
                                fill='#FFD33C'
                              />
                            </svg>
                            <span className='ml-2.5 text-xl font-medium text-white'>
                              5
                            </span>
                          </div>
                        </div>
                        <p className='text-lg text-white'>
                          It was a pleasure working with the Saturn. They
                          understood the brief correctly
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-body overflow-hidden'>
          <div className='relative container mx-auto px-4'>
            <img
              className='hidden md:block absolute top-0 right-0 w-32 lg:w-auto mt-24 xl:mt-0 -mr-8 lg:-mr-24 xl:-mr-0 z-10 animate-movePlant'
              src='casper-assets/faq/shield-robot.png'
              alt=''
            />
            <img
              className='hidden md:block absolute top-0 left-0 w-40 lg:w-auto -ml-8 lg:-ml-18 xl:-ml-0 lg:mt-96 z-10 animate-movePlanet'
              src='casper-assets/faq/robot-ball.png'
              alt=''
            />
            <div className='absolute top-0 left-0 -ml-40 -mt-80 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
            <div className='absolute bottom-0 right-0 mb-52 w-160 h-160 bg-gradient-to-t from-violet-900 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
            <div className='relative text-center mb-14'>
              <h2 className='font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-8'>
                Frequently Ask Questions
              </h2>
              <p className='text-lg text-gray-500 tracking-tight'>
                Nunc et tellus non erat accumsan aliquam eget non mi.
              </p>
            </div>
            <div className='relative max-w-2xl xl:max-w-4xl mx-auto'>
              <button className='group block w-full p-8 mb-6 text-left bg-white bg-opacity-10 rounded-3xl'>
                <div className='flex items-start justify-between'>
                  <div className='pr-4'>
                    <h4 className='text-xl text-white group-hover:text-violet-500 tracking-tight font-medium'>
                      Do you provide a complete design style?
                    </h4>
                    <p className='hidden group-hover:block max-w-xl mt-5 text-lg tracking-tight text-gray-400'>
                      Pretium ac auctor quis urna orci feugiat. Vulputate tellus
                      velit tellus orci auctor vel nulla facilisi ut. Dummy
                      conten amazing Ante nunc risus viverra vivamus.
                    </p>
                  </div>
                  <div>
                    <span className='hidden group-hover:block'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.8333 9.16663H4.16668C3.94566 9.16663 3.7337 9.25442 3.57742 9.4107C3.42114 9.56698 3.33334 9.77895 3.33334 9.99996C3.33334 10.221 3.42114 10.4329 3.57742 10.5892C3.7337 10.7455 3.94566 10.8333 4.16668 10.8333H15.8333C16.0544 10.8333 16.2663 10.7455 16.4226 10.5892C16.5789 10.4329 16.6667 10.221 16.6667 9.99996C16.6667 9.77895 16.5789 9.56698 16.4226 9.4107C16.2663 9.25442 16.0544 9.16663 15.8333 9.16663Z'
                          fill='#B680ED'
                        />
                      </svg>
                    </span>
                    <span className='block group-hover:hidden'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.8333 9.16665H10.8333V4.16665C10.8333 3.94563 10.7455 3.73367 10.5893 3.57739C10.433 3.42111 10.221 3.33331 10 3.33331C9.779 3.33331 9.56703 3.42111 9.41075 3.57739C9.25447 3.73367 9.16668 3.94563 9.16668 4.16665V9.16665H4.16668C3.94566 9.16665 3.7337 9.25444 3.57742 9.41072C3.42114 9.567 3.33334 9.77897 3.33334 9.99998C3.33334 10.221 3.42114 10.433 3.57742 10.5892C3.7337 10.7455 3.94566 10.8333 4.16668 10.8333H9.16668V15.8333C9.16668 16.0543 9.25447 16.2663 9.41075 16.4226C9.56703 16.5788 9.779 16.6666 10 16.6666C10.221 16.6666 10.433 16.5788 10.5893 16.4226C10.7455 16.2663 10.8333 16.0543 10.8333 15.8333V10.8333H15.8333C16.0544 10.8333 16.2663 10.7455 16.4226 10.5892C16.5789 10.433 16.6667 10.221 16.6667 9.99998C16.6667 9.77897 16.5789 9.567 16.4226 9.41072C16.2663 9.25444 16.0544 9.16665 15.8333 9.16665Z'
                          fill='white'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </button>
              <button className='group block w-full p-8 mb-6 text-left bg-white bg-opacity-10 rounded-3xl'>
                <div className='flex items-start justify-between'>
                  <div className='pr-4'>
                    <h4 className='text-xl text-white group-hover:text-violet-500 tracking-tight font-medium'>
                      Do you have any terms &amp; conditions?
                    </h4>
                    <p className='hidden group-hover:block max-w-xl mt-5 text-lg tracking-tight text-gray-400'>
                      Pretium ac auctor quis urna orci feugiat. Vulputate tellus
                      velit tellus orci auctor vel nulla facilisi ut. Dummy
                      conten amazing Ante nunc risus viverra vivamus.
                    </p>
                  </div>
                  <div>
                    <span className='hidden group-hover:block'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.8333 9.16663H4.16668C3.94566 9.16663 3.7337 9.25442 3.57742 9.4107C3.42114 9.56698 3.33334 9.77895 3.33334 9.99996C3.33334 10.221 3.42114 10.4329 3.57742 10.5892C3.7337 10.7455 3.94566 10.8333 4.16668 10.8333H15.8333C16.0544 10.8333 16.2663 10.7455 16.4226 10.5892C16.5789 10.4329 16.6667 10.221 16.6667 9.99996C16.6667 9.77895 16.5789 9.56698 16.4226 9.4107C16.2663 9.25442 16.0544 9.16663 15.8333 9.16663Z'
                          fill='#B680ED'
                        />
                      </svg>
                    </span>
                    <span className='block group-hover:hidden'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.8333 9.16665H10.8333V4.16665C10.8333 3.94563 10.7455 3.73367 10.5893 3.57739C10.433 3.42111 10.221 3.33331 10 3.33331C9.779 3.33331 9.56703 3.42111 9.41075 3.57739C9.25447 3.73367 9.16668 3.94563 9.16668 4.16665V9.16665H4.16668C3.94566 9.16665 3.7337 9.25444 3.57742 9.41072C3.42114 9.567 3.33334 9.77897 3.33334 9.99998C3.33334 10.221 3.42114 10.433 3.57742 10.5892C3.7337 10.7455 3.94566 10.8333 4.16668 10.8333H9.16668V15.8333C9.16668 16.0543 9.25447 16.2663 9.41075 16.4226C9.56703 16.5788 9.779 16.6666 10 16.6666C10.221 16.6666 10.433 16.5788 10.5893 16.4226C10.7455 16.2663 10.8333 16.0543 10.8333 15.8333V10.8333H15.8333C16.0544 10.8333 16.2663 10.7455 16.4226 10.5892C16.5789 10.433 16.6667 10.221 16.6667 9.99998C16.6667 9.77897 16.5789 9.567 16.4226 9.41072C16.2663 9.25444 16.0544 9.16665 15.8333 9.16665Z'
                          fill='white'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </button>
              <button className='group block w-full p-8 mb-6 text-left bg-white bg-opacity-10 rounded-3xl'>
                <div className='flex items-start justify-between'>
                  <div className='pr-4'>
                    <h4 className='text-xl text-white group-hover:text-violet-500 tracking-tight font-medium'>
                      How to apply Casper to our project?
                    </h4>
                    <p className='hidden group-hover:block max-w-xl mt-5 text-lg tracking-tight text-gray-400'>
                      Pretium ac auctor quis urna orci feugiat. Vulputate tellus
                      velit tellus orci auctor vel nulla facilisi ut. Dummy
                      conten amazing Ante nunc risus viverra vivamus.
                    </p>
                  </div>
                  <div>
                    <span className='hidden group-hover:block'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.8333 9.16663H4.16668C3.94566 9.16663 3.7337 9.25442 3.57742 9.4107C3.42114 9.56698 3.33334 9.77895 3.33334 9.99996C3.33334 10.221 3.42114 10.4329 3.57742 10.5892C3.7337 10.7455 3.94566 10.8333 4.16668 10.8333H15.8333C16.0544 10.8333 16.2663 10.7455 16.4226 10.5892C16.5789 10.4329 16.6667 10.221 16.6667 9.99996C16.6667 9.77895 16.5789 9.56698 16.4226 9.4107C16.2663 9.25442 16.0544 9.16663 15.8333 9.16663Z'
                          fill='#B680ED'
                        />
                      </svg>
                    </span>
                    <span className='block group-hover:hidden'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.8333 9.16665H10.8333V4.16665C10.8333 3.94563 10.7455 3.73367 10.5893 3.57739C10.433 3.42111 10.221 3.33331 10 3.33331C9.779 3.33331 9.56703 3.42111 9.41075 3.57739C9.25447 3.73367 9.16668 3.94563 9.16668 4.16665V9.16665H4.16668C3.94566 9.16665 3.7337 9.25444 3.57742 9.41072C3.42114 9.567 3.33334 9.77897 3.33334 9.99998C3.33334 10.221 3.42114 10.433 3.57742 10.5892C3.7337 10.7455 3.94566 10.8333 4.16668 10.8333H9.16668V15.8333C9.16668 16.0543 9.25447 16.2663 9.41075 16.4226C9.56703 16.5788 9.779 16.6666 10 16.6666C10.221 16.6666 10.433 16.5788 10.5893 16.4226C10.7455 16.2663 10.8333 16.0543 10.8333 15.8333V10.8333H15.8333C16.0544 10.8333 16.2663 10.7455 16.4226 10.5892C16.5789 10.433 16.6667 10.221 16.6667 9.99998C16.6667 9.77897 16.5789 9.567 16.4226 9.41072C16.2663 9.25444 16.0544 9.16665 15.8333 9.16665Z'
                          fill='white'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </button>
              <button className='group block w-full p-8 mb-6 text-left bg-white bg-opacity-10 rounded-3xl'>
                <div className='flex items-start justify-between'>
                  <div className='pr-4'>
                    <h4 className='text-xl text-white group-hover:text-violet-500 tracking-tight font-medium'>
                      How was the Terms &amp; Condition?
                    </h4>
                    <p className='hidden group-hover:block max-w-xl mt-5 text-lg tracking-tight text-gray-400'>
                      Pretium ac auctor quis urna orci feugiat. Vulputate tellus
                      velit tellus orci auctor vel nulla facilisi ut. Dummy
                      conten amazing Ante nunc risus viverra vivamus.
                    </p>
                  </div>
                  <div>
                    <span className='hidden group-hover:block'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.8333 9.16663H4.16668C3.94566 9.16663 3.7337 9.25442 3.57742 9.4107C3.42114 9.56698 3.33334 9.77895 3.33334 9.99996C3.33334 10.221 3.42114 10.4329 3.57742 10.5892C3.7337 10.7455 3.94566 10.8333 4.16668 10.8333H15.8333C16.0544 10.8333 16.2663 10.7455 16.4226 10.5892C16.5789 10.4329 16.6667 10.221 16.6667 9.99996C16.6667 9.77895 16.5789 9.56698 16.4226 9.4107C16.2663 9.25442 16.0544 9.16663 15.8333 9.16663Z'
                          fill='#B680ED'
                        />
                      </svg>
                    </span>
                    <span className='block group-hover:hidden'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.8333 9.16665H10.8333V4.16665C10.8333 3.94563 10.7455 3.73367 10.5893 3.57739C10.433 3.42111 10.221 3.33331 10 3.33331C9.779 3.33331 9.56703 3.42111 9.41075 3.57739C9.25447 3.73367 9.16668 3.94563 9.16668 4.16665V9.16665H4.16668C3.94566 9.16665 3.7337 9.25444 3.57742 9.41072C3.42114 9.567 3.33334 9.77897 3.33334 9.99998C3.33334 10.221 3.42114 10.433 3.57742 10.5892C3.7337 10.7455 3.94566 10.8333 4.16668 10.8333H9.16668V15.8333C9.16668 16.0543 9.25447 16.2663 9.41075 16.4226C9.56703 16.5788 9.779 16.6666 10 16.6666C10.221 16.6666 10.433 16.5788 10.5893 16.4226C10.7455 16.2663 10.8333 16.0543 10.8333 15.8333V10.8333H15.8333C16.0544 10.8333 16.2663 10.7455 16.4226 10.5892C16.5789 10.433 16.6667 10.221 16.6667 9.99998C16.6667 9.77897 16.5789 9.567 16.4226 9.41072C16.2663 9.25444 16.0544 9.16665 15.8333 9.16665Z'
                          fill='white'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </button>
              <button className='group block w-full p-8 mb-6 text-left bg-white bg-opacity-10 rounded-3xl'>
                <div className='flex items-start justify-between'>
                  <div className='pr-4'>
                    <h4 className='text-xl text-white group-hover:text-violet-500 tracking-tight font-medium'>
                      How much we can buy this product?
                    </h4>
                    <p className='hidden group-hover:block max-w-xl mt-5 text-lg tracking-tight text-gray-400'>
                      Pretium ac auctor quis urna orci feugiat. Vulputate tellus
                      velit tellus orci auctor vel nulla facilisi ut. Dummy
                      conten amazing Ante nunc risus viverra vivamus.
                    </p>
                  </div>
                  <div>
                    <span className='hidden group-hover:block'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.8333 9.16663H4.16668C3.94566 9.16663 3.7337 9.25442 3.57742 9.4107C3.42114 9.56698 3.33334 9.77895 3.33334 9.99996C3.33334 10.221 3.42114 10.4329 3.57742 10.5892C3.7337 10.7455 3.94566 10.8333 4.16668 10.8333H15.8333C16.0544 10.8333 16.2663 10.7455 16.4226 10.5892C16.5789 10.4329 16.6667 10.221 16.6667 9.99996C16.6667 9.77895 16.5789 9.56698 16.4226 9.4107C16.2663 9.25442 16.0544 9.16663 15.8333 9.16663Z'
                          fill='#B680ED'
                        />
                      </svg>
                    </span>
                    <span className='block group-hover:hidden'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.8333 9.16665H10.8333V4.16665C10.8333 3.94563 10.7455 3.73367 10.5893 3.57739C10.433 3.42111 10.221 3.33331 10 3.33331C9.779 3.33331 9.56703 3.42111 9.41075 3.57739C9.25447 3.73367 9.16668 3.94563 9.16668 4.16665V9.16665H4.16668C3.94566 9.16665 3.7337 9.25444 3.57742 9.41072C3.42114 9.567 3.33334 9.77897 3.33334 9.99998C3.33334 10.221 3.42114 10.433 3.57742 10.5892C3.7337 10.7455 3.94566 10.8333 4.16668 10.8333H9.16668V15.8333C9.16668 16.0543 9.25447 16.2663 9.41075 16.4226C9.56703 16.5788 9.779 16.6666 10 16.6666C10.221 16.6666 10.433 16.5788 10.5893 16.4226C10.7455 16.2663 10.8333 16.0543 10.8333 15.8333V10.8333H15.8333C16.0544 10.8333 16.2663 10.7455 16.4226 10.5892C16.5789 10.433 16.6667 10.221 16.6667 9.99998C16.6667 9.77897 16.5789 9.567 16.4226 9.41072C16.2663 9.25444 16.0544 9.16665 15.8333 9.16665Z'
                          fill='white'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </button>
              <button className='group block w-full p-8 text-left bg-white bg-opacity-10 rounded-3xl'>
                <div className='flex items-start justify-between'>
                  <div className='pr-4'>
                    <h4 className='text-xl text-white group-hover:text-violet-500 tracking-tight font-medium'>
                      How was the license?
                    </h4>
                    <p className='hidden group-hover:block max-w-xl mt-5 text-lg tracking-tight text-gray-400'>
                      Pretium ac auctor quis urna orci feugiat. Vulputate tellus
                      velit tellus orci auctor vel nulla facilisi ut. Dummy
                      conten amazing Ante nunc risus viverra vivamus.
                    </p>
                  </div>
                  <div>
                    <span className='hidden group-hover:block'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.8333 9.16663H4.16668C3.94566 9.16663 3.7337 9.25442 3.57742 9.4107C3.42114 9.56698 3.33334 9.77895 3.33334 9.99996C3.33334 10.221 3.42114 10.4329 3.57742 10.5892C3.7337 10.7455 3.94566 10.8333 4.16668 10.8333H15.8333C16.0544 10.8333 16.2663 10.7455 16.4226 10.5892C16.5789 10.4329 16.6667 10.221 16.6667 9.99996C16.6667 9.77895 16.5789 9.56698 16.4226 9.4107C16.2663 9.25442 16.0544 9.16663 15.8333 9.16663Z'
                          fill='#B680ED'
                        />
                      </svg>
                    </span>
                    <span className='block group-hover:hidden'>
                      <svg
                        width={20}
                        height={20}
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M15.8333 9.16665H10.8333V4.16665C10.8333 3.94563 10.7455 3.73367 10.5893 3.57739C10.433 3.42111 10.221 3.33331 10 3.33331C9.779 3.33331 9.56703 3.42111 9.41075 3.57739C9.25447 3.73367 9.16668 3.94563 9.16668 4.16665V9.16665H4.16668C3.94566 9.16665 3.7337 9.25444 3.57742 9.41072C3.42114 9.567 3.33334 9.77897 3.33334 9.99998C3.33334 10.221 3.42114 10.433 3.57742 10.5892C3.7337 10.7455 3.94566 10.8333 4.16668 10.8333H9.16668V15.8333C9.16668 16.0543 9.25447 16.2663 9.41075 16.4226C9.56703 16.5788 9.779 16.6666 10 16.6666C10.221 16.6666 10.433 16.5788 10.5893 16.4226C10.7455 16.2663 10.8333 16.0543 10.8333 15.8333V10.8333H15.8333C16.0544 10.8333 16.2663 10.7455 16.4226 10.5892C16.5789 10.433 16.6667 10.221 16.6667 9.99998C16.6667 9.77897 16.5789 9.567 16.4226 9.41072C16.2663 9.25444 16.0544 9.16665 15.8333 9.16665Z'
                          fill='white'
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </section>
        <section className='relative py-12 sm:pt-24 bg-body overflow-hidden'>
          <div className='absolute top-0 right-0 -mt-112 -mr-52 w-186 h-186 bg-gradient-to-t from-purple-500 via-darkBlue-900 to-transparent rounded-full filter blur-4xl' />
          <img
            className='hidden sm:block absolute top-0 left-1/2 mt-8 lg:mt-16 md:ml-20 lg:ml-0 animate-spinStar'
            src='casper-assets/footer/blink-star.png'
            alt=''
          />
          <div className='relative container mx-auto px-4'>
            <div className='sm:flex items-center justify-between mb-16'>
              <div className='max-w-xs lg:max-w-md mb-10 sm:mb-0'>
                <h3 className='text-3xl font-medium text-white'>
                  Gain valuable insights into your users' preferences
                </h3>
              </div>
              <div className='flex flex-col lg:flex-row sm:items-end'>
                <a
                  className='inline-flex mb-4 lg:mb-0 lg:mr-4 py-2 px-3 w-36 justify-center items-center rounded-lg bg-white bg-opacity-10 hover:bg-opacity-20'
                  href='#'
                >
                  <img
                    className='block mr-2.5'
                    src='casper-assets/footer/app-store.svg'
                    alt=''
                  />
                  <div>
                    <span className='block text-xs text-gray-500'>
                      Download on
                    </span>
                    <span className='block text-sm font-medium text-white'>
                      App Store
                    </span>
                  </div>
                </a>
                <a
                  className='inline-flex py-2 px-3 w-36 justify-center items-center rounded-lg bg-white bg-opacity-10 hover:bg-opacity-20'
                  href='#'
                >
                  <img
                    className='block mr-2.5'
                    src='casper-assets/footer/google-store.svg'
                    alt=''
                  />
                  <div>
                    <span className='block text-xs text-gray-500'>
                      Download on
                    </span>
                    <span className='block text-sm font-medium text-white'>
                      Play Store
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div className='pt-16 border-t-2 border-gray-800'>
              <div className='flex flex-wrap items-end -mx-4'>
                <div className='w-full md:w-2/5 px-4 mb-12'>
                  <div className='max-w-xs'>
                    <a className='inline-block mb-8' href='#'>
                      <img
                        className='block'
                        src='casper-assets/logos/casper-logo-white.svg'
                        alt=''
                      />
                    </a>
                    <p className='text-white tracking-tight mb-8'>
                      CASPER is here to revolutionize the way you interact with
                      your audience.
                    </p>
                    <span className='text-gray-600 tracking-tight'>
                      © Casper. All rights reserved
                    </span>
                  </div>
                </div>
                <div className='w-full md:w-3/5 px-4'>
                  <div className='flex flex-wrap lg:flex-nowrap -mx-4'>
                    <div className='w-auto mb-10 mr-10 xl:mr-24 px-4'>
                      <h4 className='text-xl font-medium text-white tracking-tight mb-4'>
                        Services
                      </h4>
                      <ul>
                        <li className='mb-4'>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Email Marketing
                          </a>
                        </li>
                        <li className='mb-4'>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Campaigns
                          </a>
                        </li>
                        <li className='mb-4'>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Branding
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Offline
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-auto mb-10 mr-10 xl:mr-24 px-4'>
                      <h4 className='text-xl font-medium text-white tracking-tight mb-4'>
                        About
                      </h4>
                      <ul>
                        <li className='mb-4'>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Our Story
                          </a>
                        </li>
                        <li className='mb-4'>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Benefits
                          </a>
                        </li>
                        <li className='mb-4'>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Team
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Careers
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='w-auto px-4'>
                      <h4 className='text-xl font-medium text-white tracking-tight mb-4'>
                        Help
                      </h4>
                      <ul>
                        <li className='mb-4'>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            FAQs
                          </a>
                        </li>
                        <li>
                          <a
                            className='inline-block text-gray-500 hover:text-white tracking-tight'
                            href='#'
                          >
                            Contact Us
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

