import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const meta = {
	title: '',
	meta: [],
	link: [],
	style: [],
	script: [],
}

export default function IndexPage() {
	return (
		<React.Fragment>
			<HelmetProvider>
				<Helmet {...meta}></Helmet>
			</HelmetProvider>
			<>
				<section className='relative bg-body overflow-hidden md:pt-24 pt-12 lg:pt-32'>
					<div className='relative container mx-auto px-4'>
						<img
							className='hidden md:block absolute top-0 right-0 lg:-mt-20 mr-20 lg:mr-40 animate-spinStar z-10'
							src='casper-assets/services/blink-star.png'
							alt=''
						/>
						<div className='relative max-w-lg lg:max-w-8xl mx-auto'>
							<div className='absolute top-0 left-0 -mt-96 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl'></div>
							<div className='absolute bottom-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl'></div>
							<div className='relative flex flex-wrap items-end -mx-4 mb-12'>
								<div className='w-full lg:w-3/5 xl:w-auto px-4 mb-8 lg:mb-0'>
									<div className='max-w-2xl'>
										<span className='block font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-transparent bg-clip-text bg-gradient-to-br from-violet-500 via-blueGray-800 to-sky-900 tracking-tight'>
											5+
										</span>
										<h2 className='font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6'>
											Home Pages
										</h2>
										<p className='max-w-xl text-xl text-gray-500 leading-relaxed tracking-tight'>
											We've provided a vast and unlimited template library for all your use cases
										</p>
									</div>
								</div>
								<div className='w-full lg:w-2/5 xl:w-auto ml-auto px-4 lg:text-right'>
									<a
										className='group inline-flex w-full md:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-violet-800 bg-violet-500 hover:bg-white transition duration-200 rounded-full'
										href='#'>
										<span className='mr-2'>SEE MORE PROJECTS</span>
										<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
											<path
												d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z'
												fill='currentColor'></path>
										</svg>
									</a>
								</div>
							</div>
							<div className='relative flex flex-wrap -mx-4'>
								<div className='w-full  lg:w-1/2 px-4 lg:mb-0'>
									<a
										className='group block mb-6 rounded-3xl overflow-hidden'
										target='_blank'
										rel='noreferrer'
										href='https://casper-ai.templates.broadtray.com/index1'>
										<img className='block w-full object-cover h-full' src='casper-assets/displays/home-page-1-part.png' alt='' />
										<div className='p-8 bg-violet-600  transition duration-200'>
											<div className='sm:flex mb-6 items-center justify-between'>
												<span className='text-xl font-medium text-white tracking-tight'>Home Page 1</span>
											</div>
											<p className='text-2xl font-medium  text-white transition duration-200'>
												Click to explore our responsive and mobile optimized demo of Home Page 1
											</p>
										</div>
									</a>
									<a
										className='group block rounded-3xl overflow-hidden'
										target='_blank'
										rel='noreferrer'
										href='https://casper-ai.templates.broadtray.com/index3'>
										<img className='block w-full object-cover' src='casper-assets/displays/home-page-3-part.png' alt='' />
										<div className='p-8 bg-violet-600  transition duration-200'>
											<div className='sm:flex mb-6 items-center justify-between'>
												<span className='text-xl font-medium text-white tracking-tight'>Home Page 3</span>
											</div>
											<p className='text-2xl font-medium  text-white transition duration-200'>
												Click to explore our responsive and mobile optimized demo of Home Page 3
											</p>
										</div>
									</a>
								</div>
								<div className='w-full lg:w-1/2 px-4 lg:pt-20'>
									<a
										className='group block mb-6 rounded-3xl overflow-hidden'
										target='_blank'
										rel='noreferrer'
										href='https://casper-ai.templates.broadtray.com/index2'>
										<img className='block w-full object-cover h-full' src='casper-assets/displays/home-page-2-part.png' alt='' />
										<div className='p-8 bg-violet-600  transition duration-200'>
											<div className='sm:flex mb-6 items-center justify-between'>
												<span className='text-xl font-medium text-white tracking-tight'>Home Page 2</span>
											</div>
											<p className='text-2xl font-medium  text-white transition duration-200'>
												Click to explore our responsive and mobile optimized demo of Home Page 2
											</p>
										</div>
									</a>
									<a
										className='group block rounded-3xl overflow-hidden'
										target='_blank'
										rel='noreferrer'
										href='https://casper-ai.templates.broadtray.com/index4'>
										<img className='block h-96 w-full object-cover' src='casper-assets/displays/home-page-4-part.png' alt='' />
										<div className='p-8 bg-violet-600  transition duration-200'>
											<div className='sm:flex mb-6 items-center justify-between'>
												<span className='text-xl font-medium text-white tracking-tight'>Home Page 4</span>
											</div>
											<p className='text-2xl font-medium  text-white transition duration-200'>
												Click to explore our responsive and mobile optimized demo of Home Page 4
											</p>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className='relative bg-body overflow-hidden mt-12'>
					<div className='relative container mx-auto px-4'>
						<img
							className='hidden md:block absolute top-0 right-0 lg:-mt-20 mr-20 lg:mr-40 animate-spinStar z-10'
							src='casper-assets/services/blink-star.png'
							alt=''
						/>
						<div className='relative max-w-lg lg:max-w-8xl mx-auto'>
							<div className='absolute top-0 left-0 -mt-96 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl'></div>
							<div className='absolute bottom-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl'></div>
							<div className='relative flex flex-wrap -mx-4 -mt-22'>
								<div className='w-full lg:w-1/2 px-4 mb-6 lg:mb-0'>
									<a
										className='group block mb-6 rounded-3xl overflow-hidden'
										target='_blank'
										rel='noreferrer'
										href='https://casper-ai.templates.broadtray.com/index5'>
										<img className='block h-96 w-full object-cover' src='casper-assets/displays/home-page-5-part.png' alt='' />
										<div className='p-8 bg-violet-600  transition duration-200'>
											<div className='sm:flex mb-6 items-center justify-between'>
												<span className='text-xl font-medium text-white tracking-tight'>Home Page 5</span>
											</div>
											<p className='text-2xl font-medium  text-white transition duration-200'>
												Click to explore our responsive and mobile optimized demo of Home Page 5
											</p>
										</div>
									</a>
									<a className='group block rounded-3xl overflow-hidden' href='#'></a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section class='relative bg-body overflow-hidden md:pt-24 pt-12 lg:pt-32'>
					<div class='relative container mx-auto px-4'>
						<img
							class='hidden md:block absolute top-0 right-0 lg:-mt-20 mr-20 lg:mr-40 animate-spinStar z-10'
							src='casper-assets/services/blink-star.png'
							alt=''
						/>
						<div class='relative max-w-lg lg:max-w-8xl mx-auto'>
							<div class='absolute top-0 left-0 -mt-96 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl'></div>
							<div class='absolute bottom-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl'></div>
							<div class='relative flex flex-wrap items-end -mx-4 mb-12'>
								<div class='w-full lg:w-3/5 xl:w-auto px-4 mb-8 lg:mb-0'>
									<div class='max-w-2xl'>
										<span class='block font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-transparent bg-clip-text bg-gradient-to-br from-violet-500 via-blueGray-800 to-sky-900 tracking-tight'>
											3+
										</span>
										<h2 class='font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6'>
											About us Pages
										</h2>
										<p class='max-w-xl text-xl text-gray-500 leading-relaxed tracking-tight'>
											We've provided a vast and unlimited template library for all your use cases
										</p>
									</div>
								</div>
							</div>
							<div class='relative flex flex-wrap -mx-4'>
								<div class='w-full lg:w-1/2 px-4 mb-6 lg:mb-0'>
									<a
										class='group block mb-6 rounded-3xl overflow-hidden'
										target='_blank'
										rel='noreferrer'
										href='https://casper-ai.templates.broadtray.com/about1'>
										<img class='block w-full object-cover h-full' src='casper-assets/displays/about-page1-part.png' alt='' />
										<div className='p-8 bg-violet-600  transition duration-200'>
											<div className='sm:flex mb-6 items-center justify-between'>
												<span className='text-xl font-medium text-white tracking-tight'>About us Page 1</span>
											</div>
											<p className='text-2xl font-medium  text-white transition duration-200'>
												Click to explore our responsive and mobile optimized demo of About us Page 1
											</p>
										</div>
									</a>
									<a
										class='group block rounded-3xl overflow-hidden'
										target='_blank'
										rel='noreferrer'
										href='https://casper-ai.templates.broadtray.com/about3'>
										<img class='block w-full object-cover' src='casper-assets/displays/about-page3-part.png' alt='' />
										<div className='p-8 bg-violet-600  transition duration-200'>
											<div className='sm:flex mb-6 items-center justify-between'>
												<span className='text-xl font-medium text-white tracking-tight'>About Page 3</span>
											</div>
											<p className='text-2xl font-medium  text-white transition duration-200'>
												Click to explore our responsive and mobile optimized demo of About Page 3
											</p>
										</div>
									</a>
								</div>
								<div class='w-full lg:w-1/2 px-4 lg:pt-20'>
									<a
										class='group block mb-6 rounded-3xl overflow-hidden'
										target='_blank'
										rel='noreferrer'
										href='https://casper-ai.templates.broadtray.com/about2'>
										<img class='block w-full object-cover h-full' src='casper-assets/displays/about-page2-part.png' alt='' />
										<div className='p-8 bg-violet-600  transition duration-200'>
											<div className='sm:flex mb-6 items-center justify-between'>
												<span className='text-xl font-medium text-white tracking-tight'>About Page 2</span>
											</div>
											<p className='text-2xl font-medium  text-white transition duration-200'>
												Click to explore our responsive and mobile optimized demo of About Page 2
											</p>
										</div>
									</a>
									<a
										class='group block rounded-3xl overflow-hidden'
										target='_blank'
										rel='noreferrer'
										href='https://casper-ai.templates.broadtray.com/about2'></a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section class='relative bg-body overflow-hidden md:pt-24 pt-12 lg:pt-32'>
					<div class='relative container mx-auto px-4'>
						<img
							class='hidden md:block absolute top-0 right-0 lg:-mt-20 mr-20 lg:mr-40 animate-spinStar z-10'
							src='casper-assets/services/blink-star.png'
							alt=''
						/>
						<div class='relative max-w-lg lg:max-w-8xl mx-auto'>
							<div class='absolute top-0 left-0 -mt-96 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl'></div>
							<div class='absolute bottom-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl'></div>
							<div class='relative flex flex-wrap items-end -mx-4 mb-12'>
								<div class='w-full lg:w-3/5 xl:w-auto px-4 mb-8 lg:mb-0'>
									<div class='max-w-2xl'>
										<span class='block font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-transparent bg-clip-text bg-gradient-to-br from-violet-500 via-blueGray-800 to-sky-900 tracking-tight'>
											3+
										</span>
										<h2 class='font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6'>
											Contact us Pages
										</h2>
										<p class='max-w-xl text-xl text-gray-500 leading-relaxed tracking-tight'>
											We've provided a vast and unlimited template library for all your use cases
										</p>
									</div>
								</div>
							</div>
							<div class='relative flex flex-wrap -mx-4'>
								<div class='w-full lg:w-1/2 px-4 mb-6 lg:mb-0'>
									<a
										class='group block mb-6 rounded-3xl overflow-hidden'
										target='_blank'
										rel='noreferrer'
										href='https://casper-ai.templates.broadtray.com/contact1'>
										<img class='block w-full object-cover h-full' src='casper-assets/displays/contact-page1-part.png' alt='' />
										<div className='p-8 bg-violet-600  transition duration-200'>
											<div className='sm:flex mb-6 items-center justify-between'>
												<span className='text-xl font-medium text-white tracking-tight'>Contact Page 1</span>
											</div>
											<p className='text-2xl font-medium  text-white transition duration-200'>
												Click to explore our responsive and mobile optimized demo of Contact Page 1
											</p>
										</div>
									</a>
									<a
										class='group block rounded-3xl overflow-hidden'
										target='_blank'
										rel='noreferrer'
										href='https://casper-ai.templates.broadtray.com/contact3'>
										<img class='block w-full object-cover' src='casper-assets/displays/contact-page3-part.png' alt='' />
										<div className='p-8 bg-violet-600  transition duration-200'>
											<div className='sm:flex mb-6 items-center justify-between'>
												<span className='text-xl font-medium text-white tracking-tight'>Contact Page 3</span>
											</div>
											<p className='text-2xl font-medium  text-white transition duration-200'>
												Click to explore our responsive and mobile optimized demo of Contact Page 3
											</p>
										</div>
									</a>
								</div>
								<div class='w-full lg:w-1/2 px-4 lg:pt-20'>
									<a
										class='group block mb-6 rounded-3xl overflow-hidden'
										target='_blank'
										rel='noreferrer'
										href='https://casper-ai.templates.broadtray.com/contact2'>
										<img class='block w-full object-cover h-full' src='casper-assets/displays/contact-page2-part.png' alt='' />
										<div className='p-8 bg-violet-600  transition duration-200'>
											<div className='sm:flex mb-6 items-center justify-between'>
												<span className='text-xl font-medium text-white tracking-tight'>Contact Page 2</span>
											</div>
											<p className='text-2xl font-medium  text-white transition duration-200'>
												Click to explore our responsive and mobile optimized demo of Contact Page 2
											</p>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section class='relative py-12 md:py-24 bg-body overflow-hidden'>
					<div class='relative container mx-auto px-4'>
						<div class='absolute top-0 left-0 ml-52 -mt-40 w-160 h-160 bg-gradient-to-t from-purple-600 to-darkBlue-900 rounded-full filter blur-4xl'></div>
						<div class='absolute top-0 left-0 ml-148 -mt-40 w-135 h-135 bg-gradient-to-t from-blue-500 to-darkBlue-900 rounded-full filter blur-4xl'></div>
						<div class='max-w-8xl mx-auto'>
							<div class='relative flex flex-wrap items-end -mx-4'>
								<div class='w-full lg:w-1/2 px-12 mb-20 lg:mb-0'>
									<div class='max-w-lg lg:max-w-xl mx-auto lg:mx-0'>
										<h2 class='font-heading text-4xl xs:text-5xl sm:text-6xl font-medium text-white tracking-tight mb-2'>
											More pages...
										</h2>
										<p class='text-xl text-gray-500 mb-16'>
											We've provided assets for all your use cases and more assets are already in the works and will be launched
											soon.
										</p>
										<div class='max-w-md'>
											<a
												class='block pb-10 mb-10 border-b border-white border-opacity-20'
												target='_blank'
												rel='noreferrer'
												href='https://casper-ai.templates.broadtray.com/faq1'>
												<div class='xs:flex items-start xs:-mx-4'>
													<div class='xs:px-4 mb-4 xs:mb-0'>
														<h3 class='text-2xl text-white font-medium'>FAQ</h3>
													</div>
												</div>
											</a>
											<a
												class='block pb-10 mb-10 border-b border-white border-opacity-20'
												target='_blank'
												rel='noreferrer'
												href='https://casper-ai.templates.broadtray.com/blog1'>
												<div class='xs:flex items-start xs:-mx-4'>
													<div class='xs:px-4 mb-4 xs:mb-0'>
														<h3 class='text-2xl text-white font-medium'>Blog 1</h3>
													</div>
												</div>
											</a>
											<a
												class='block pb-10 mb-10 border-b border-white border-opacity-20'
												target='_blank'
												rel='noreferrer'
												href='https://casper-ai.templates.broadtray.com/blog2'>
												<div class='xs:flex items-start xs:-mx-4'>
													<div class='xs:px-4 mb-4 xs:mb-0'>
														<h3 class='text-2xl text-white font-medium'>Blog 2</h3>
													</div>
												</div>
											</a>
											<a
												class='block pb-10 mb-10 border-b border-white border-opacity-20'
												target='_blank'
												rel='noreferrer'
												href='https://casper-ai.templates.broadtray.com/login1'>
												<div class='xs:flex items-start xs:-mx-4'>
													<div class='xs:px-4 mb-4 xs:mb-0'>
														<h3 class='text-2xl text-white font-medium'>Login 1</h3>
													</div>
												</div>
											</a>
											<a
												class='block pb-10 mb-10 border-b border-white border-opacity-20'
												target='_blank'
												rel='noreferrer'
												href='https://casper-ai.templates.broadtray.com/login2'>
												<div class='xs:flex items-start xs:-mx-4'>
													<div class='xs:px-4 mb-4 xs:mb-0'>
														<h3 class='text-2xl text-white font-medium'>Login 2</h3>
													</div>
												</div>
											</a>
											<a
												class='block pb-10 mb-10 border-b border-white border-opacity-20'
												target='_blank'
												rel='noreferrer'
												href='https://casper-ai.templates.broadtray.com/signup1'>
												<div class='xs:flex items-start xs:-mx-4'>
													<div class='xs:px-4 mb-4 xs:mb-0'>
														<h3 class='text-2xl text-white font-medium'>Signup 1</h3>
													</div>
												</div>
											</a>
											<a
												class='block pb-10 mb-10 border-b border-white border-opacity-20'
												target='_blank'
												rel='noreferrer'
												href='https://casper-ai.templates.broadtray.com/signup2'>
												<div class='xs:flex items-start xs:-mx-4'>
													<div class='xs:px-4 mb-4 xs:mb-0'>
														<h3 class='text-2xl text-white font-medium'>Signup 2</h3>
													</div>
												</div>
											</a>
											<a
												class='block pb-10 mb-10 border-b border-white border-opacity-20'
												target='_blank'
												rel='noreferrer'
												href='https://casper-ai.templates.broadtray.com/signup3'>
												<div class='xs:flex items-start xs:-mx-4'>
													<div class='xs:px-4 mb-4 xs:mb-0'>
														<h3 class='text-2xl text-white font-medium'>Signup 3</h3>
													</div>
												</div>
											</a>
										</div>
									</div>
								</div>
								<div class='w-full lg:w-1/2 px-4'>
									<img class='block mx-auto lg:mr-0' src='casper-assets/careers/vertical-image.png' alt='' />
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		</React.Fragment>
	)
}
