import React from 'react'
import { Routes, Route } from 'react-router-dom'

import IndexPage from './pages/Index.js'
import Index1Page from './pages/Index1.js'
import Index2Page from './pages/Index2.js'
import Index3Page from './pages/Index3.js'
import Index4Page from './pages/Index4.js'
import Index5Page from './pages/Index5.js'
import About1Page from './pages/About1.js'
import About2Page from './pages/About2.js'
import About3Page from './pages/About3.js'
import Login1Page from './pages/Login1.js'
import Login2Page from './pages/Login2.js'
import Signup1Page from './pages/Signup1.js'
import Signup2Page from './pages/Signup2.js'
import Signup3Page from './pages/Signup3.js'
import Faq1Page from './pages/Faq1.js'
import Contact1Page from './pages/Contact1.js'
import Contact2Page from './pages/Contact2.js'
import Contact3Page from './pages/Contact3.js'
import Blog1Page from './pages/Blog1.js'
import Blog2Page from './pages/Blog2.js'

function App() {
	return (
		<Routes>
			<Route path='/' element={<IndexPage />} />

			<Route path='/index1' element={<Index1Page />} />

			<Route path='/index2' element={<Index2Page />} />

			<Route path='/index3' element={<Index3Page />} />

			<Route path='/index4' element={<Index4Page />} />

			<Route path='/index5' element={<Index5Page />} />

			<Route path='/about1' element={<About1Page />} />

			<Route path='/about2' element={<About2Page />} />

			<Route path='/about3' element={<About3Page />} />

			<Route path='/login1' element={<Login1Page />} />

			<Route path='/login2' element={<Login2Page />} />

			<Route path='/signup1' element={<Signup1Page />} />

			<Route path='/signup2' element={<Signup2Page />} />

			<Route path='/signup3' element={<Signup3Page />} />

			<Route path='/faq1' element={<Faq1Page />} />

			<Route path='/contact1' element={<Contact1Page />} />

			<Route path='/contact2' element={<Contact2Page />} />

			<Route path='/contact3' element={<Contact3Page />} />

			<Route path='/blog1' element={<Blog1Page />} />

			<Route path='/blog2' element={<Blog2Page />} />
		</Routes>
	)
}

export default App
